import React, { Component } from 'react';
import { Input, Modal, Button, message, Checkbox, Table, Upload, Image } from 'antd';
import { getLabels, putAddlabel, getOrderManager, putOrderDevelop } from '../../../services/orderCore';
import styles from './index.module.css';
import { DeleteOutlined, PlusOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
// import WorkerModal from '../allOrder/workerModal';
import { getDepartment_Data, setOssBaseAuth, getOssBaseAuth } from '../../../config/storage';
import { CloseOutlined } from '@ant-design/icons';
import KefuModal from './kefuModal';
import { ossUpload, } from '../../../utils/ossUpload.js';
import picc from '../../../resource/png/background.jpg';

class OrderTipModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
      workerType: false,
      workerComplaint: false,
      merchantType: false,
      labelData: [],
      labelDataType: 0,
      data: [],
      pic: [],
      kefuData: [],
      responsible: {
        worker: 0,
        workerName: '',
        shop: 0,
        shopName: '',
      },
      onTextArea: '',
      isToDevelop: false,
    }
    this.baseUrl = getDepartment_Data().service;
    // this.workerModal = React.createRef();
    this.kefuModal = React.createRef();
    this.allRecorColumns = [
      {
        title: '操作类型',
        dataIndex: 'type',
        align: 'center',
        width: 100,
        className: 'padding5'
      },
      {
        title: '客服',
        dataIndex: 'name',
        align: 'center',
        width: 100,
        className: 'padding5'
      },
      {
        title: '操作时间',
        dataIndex: 'submit_time',
        align: 'center',
        width: 120,
        className: 'padding5'
      },
      {
        title: '操作内容',
        dataIndex: 'content',
        align: 'center',
        width: 300,
        className: 'padding5',
        render: (text, record, index) => {
          return (<div className='bold'>{record.content}</div>);
        }
      },
    ]
  }
  render() {
    const { visible, labelData, labelDataType, kefuData, confirmLoading, workerType, merchantType,
      responsible, data, workerComplaint, pic, base, baseUrl, onTextArea, isToDevelop } = this.state;
    return (
      <Modal
        title={'订单标签'}
        visible={visible}
        width={800}
        onCancel={this.onCancel}
        onOk={this.onOK}
        okText='确定'
        cancelText='取消'
        confirmLoading={confirmLoading}
      >
        <div className='w100'>
          <Table
            columns={this.allRecorColumns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={{ defaultPageSize: 5 }}
          />
          <div className='row-l vertical-lt margin-t10'>
            <span className={`${styles['title_style']} margin-r10`}>选择标签:</span>
            <div className={`${styles['labelData_styles']} row-w`}>
              {
                labelData.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className={`${styles['quickLabel_div']} ${labelDataType === item.id && styles['quickLabel_Color']} margin-b5`}
                      onClick={isToDevelop ? null : this.onLabelDataClick.bind(this, item.id)}
                    >
                      {item.name}
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className='row-l vertical-lt margin-t10'>
            <span className={`${styles['title_style']} margin-r10`}>标签备注:</span>
            <Input.TextArea value={onTextArea} rows={3} placeholder="请输入标签备注" className='w680' onChange={this.onTextArea} />
          </div>
          <div className='row-l vertical-lt margin-t10'>
            <span className={`${styles['title_style']} margin-r10`}>图片备注:</span>
            <Upload
              type='file'
              beforeUpload={() => {
                return false;
              }}
              fileList={[]}
              accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
              // accept='image/*'
              multiple={true}
              onChange={this.onAddPic.bind(this)}
            >
              <div className={`row cursor-pointer ${styles['pic-div']}`}>
                <div className="row h100">
                  <PlusOutlined />
                  <span>
                    新增图片
                  </span>
                </div>
              </div>
            </Upload>
            {
              pic.map((item, index) => {
                return (
                  <div key={index} className={`${styles['content']}`}>
                    <div className={`row ${styles['pic-icon']}`} onClick={this.delInstall.bind(this, index)}><DeleteOutlined /></div>
                    <img
                      alt="暂无图片"
                      className={`${styles['pic']}`}
                      src={`${this.baseUrl}/upload/${item}`}
                    />
                    <div className={`row ${styles['content-div']}`}>
                      <EyeOutlined
                        className={`${styles['pic-icon-E']}`}
                        onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                        theme="outlined"
                        title="预览"
                      />
                    </div>
                  </div>
                );
              })
            }
          </div>
          <div className='row-l vertical-lt margin-t10'>
            <div className='col-t vertical-lt'>
              <div className='row-l vertical-lt '>
                <span className={`${styles['title_style']} margin-r10`}>处理人员:</span>
                <div className={`${styles['box_styles']} row-w vertical-lt`}>
                  {
                    kefuData.map((item, index) => {
                      return (
                        <div key={index} className={`${styles['box_tip_styles']}`}>
                          {item.name}
                          <CloseOutlined
                            className={`${styles['color_dele']} margin-l5 cursor-pointer`}
                            onClick={this.onDele.bind(this, index)}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className='row-l margin-t5'>
                <span className={`${styles['title_style']} margin-r10`} />
                {
                  responsible.shop !== 0 &&
                  <Button onClick={this.onCheckResponsible.bind(this, responsible.shop, responsible.shopName)}
                    type='primary' className='margin-r10'
                  >同步给商家负责人</Button>
                }
                {
                  responsible.worker !== 0 &&
                  <Button onClick={this.onCheckResponsible.bind(this, responsible.worker, responsible.workerName)}
                    type='primary'
                  >同步到师傅负责人</Button>
                }
              </div>
            </div>
            <div className='col margin-l10 vertical-lt'>
              <div className={`${styles['bt_styles']}`} onClick={this.openModal.bind(this, '客服')}>添加</div>
              <div>
                <Checkbox checked={merchantType} onChange={this.onCheckboxChang.bind(this, '商家')}>同步到商家备注</Checkbox>
              </div>
              <div>
                <Checkbox checked={workerType} onChange={this.onCheckboxChang.bind(this, '师傅')}>同步到师傅备注</Checkbox>
              </div>
              <div>
                <Checkbox checked={workerComplaint} onChange={this.onCheckboxChang.bind(this, '投诉')}>同步到师傅投诉</Checkbox>
              </div>
            </div>
          </div>
        </div>
        <KefuModal
          ref={this.kefuModal}
          updateTable={this.updateTable}
        />
      </Modal>
    );
  }
  onCheckboxChang = (type, e) => {
    let { merchantType, workerType, workerComplaint } = this.state;
    if (type === '商家') {
      merchantType = e.target.checked;
    } else if (type === '师傅') {
      workerType = e.target.checked;
    } else {
      workerComplaint = e.target.checked;
    }
    this.setState({
      merchantType,
      workerType,
      workerComplaint
    })
  }
  onDele = (index) => {
    let kefuData = [...this.state.kefuData];
    kefuData.splice(index, 1);
    this.setState({
      kefuData
    })
  }
  onTextArea = (e) => {
    this.setState({
      onTextArea: e.target.value
    })
  }
  updateTable = (row, type) => {
    if (row) {
      let kefuData = [...this.state.kefuData];
      kefuData = kefuData.concat(row);
      let keys = {};
      let arr_ = [];
      kefuData.forEach((item, index) => {
        !keys[item['name']] ? keys[item['name']] = true && arr_.push(item) : null
      });
      this.setState({
        kefuData: arr_
      })
    }
  }
  //上传图片
  onAddPic = async (e) => {
    let pic = [...this.state.pic];
    if (pic.length >= 3) {
      message.warn('图片不可超过3张');
    } else {
      let { file } = e;
      let name = await ossUpload(file, null);
      pic.push(name);
      this.setState({
        pic: pic
      })
    }
  }
  delInstall = (e) => {
    let pic = [...this.state.pic];
    pic.splice(e, 1);
    this.setState({
      pic,
    });
  }
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  //打开modal
  openModal = (e) => {
    this.kefuModal.current.onShowModal(e);
  }
  //获取标签
  getLabelsList = async () => {
    let res = await getLabels();
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let kefuData = [{
      name: getDepartment_Data().name,
      id: getDepartment_Data().id,
    }]
    this.setState({
      labelData: res.data,
      kefuData: kefuData
    })
  }
  onLabelDataClick = (e) => {
    const { responsible } = this.state;
    if (e === 58 && responsible.worker !== 0) {
      this.onCheckResponsible(responsible.worker, responsible.workerName)
    }
    this.setState({
      labelDataType: e
    })
  }
  // getOrderManager
  getResponsible = async (shop, worker) => {
    let responsible = { ...this.state.responsible };
    let shopRes = await getOrderManager('shop', shop);
    if (shopRes.status === 200) {
      responsible.shop = shopRes.data[0] ? shopRes.data[0].id : 0;
      responsible.shopName = shopRes.data[0] ? shopRes.data[0].name : '';
    }
    let workerRes = await getOrderManager('worker', worker);
    if (shopRes.status === 200) {
      responsible.worker = workerRes.data[0] ? workerRes.data[0].id : 0;
      responsible.workerName = workerRes.data[0] ? workerRes.data[0].name : '';
    }
    this.setState({
      responsible
    })
  }
  onCheckResponsible = (id, name) => {
    let kefuData = _.cloneDeep(this.state.kefuData);
    let index = kefuData.findIndex(r => r.id === id);
    if (index < 0) {
      kefuData.push({
        id: id,
        name: name,
      });
    } else {
      message.warn('已添加');
      return;
    }
    this.setState({
      kefuData,
    });
  }
  onShowModal = (record, isToDevelop = false) => {
    let resource = record.serviceFollows.sort(function (a, b) {
      return b.id - a.id;
    })
    let labelDataType = isToDevelop ? 20 : 0;
    this.setState({
      id: record.id,
      visible: true,
      data: resource,
      isToDevelop,
      labelDataType,
    });
    this.getResponsible(record.shop, record.workerId);
    this.getLabelsList()
  }

  onOK = async () => {
    let { id, labelData, labelDataType, kefuData, onTextArea, merchantType,
      workerType, workerComplaint, pic, isToDevelop, } = this.state;
    if (labelDataType === 0) {
      message.warn('请选择标签');
      return;
    }
    this.setState({
      confirmLoading: true
    })
    let data = labelData.filter(r => r.id === labelDataType);
    let newData = {
      label: data[0].id,
      content: onTextArea,
      name: data[0].name,
      checkers: kefuData,
      addtoShop: merchantType,
      addtoWorker: workerType,
      addtoAppeal: workerComplaint,
      pic: JSON.stringify(pic),
    }
    let res
    if (isToDevelop) {
      res = await putOrderDevelop(id);
      this.setState({
        confirmLoading: false
      })
    } else {
      res = await putAddlabel(id, newData);
      this.setState({
        confirmLoading: false
      })
    }
    if (res.status !== 204) {
      this.setState({
        confirmLoading: false
      })
      message.error(res.data);
      return;
    }
    this.onCancel();
    this.props.updateTable();
  }
  onCancel = () => {
    let kefuData = [{
      name: getDepartment_Data().name,
      id: getDepartment_Data().id,
    }]
    this.setState({
      visible: false,
      pic: [],
      isToDevelop: false,
      labelDataType: 0,
      kefuData: kefuData,
      onTextArea: '',
      confirmLoading: false
    })
  }
}

export default OrderTipModal;