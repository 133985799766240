import React, { Component } from 'react';
import { Input, Modal, Radio, message } from 'antd';
import styles from './modal.module.css';

const newData = {
  id: 0,
  order: 0,
  room: '主卧',
  width: '0',
  width_remark: '满墙',
  height: '0',
  height_remark: '石膏线下到地面',
  prop1: '罗马杆',//产品类型（罗马杆）
  prop2: '侧装',//安装方式（顶装）
  prop3: '无',///窗帘盒/
  prop4: '0',//窗帘盒尺寸（0.2*0.3）
  prop5: '和客户沟通',//层数 //成品帘拉绳
  prop6: '和客户沟通',//开合方式
  prop7: '普通窗户',//窗户类型
  prop8: '和客户沟通',//离地尺寸
  prop9: '混凝土',//安装墙面材质
  prop10: '否',//是否超高
  prop11: '否',//是否拆旧
  prop12: '0',//窗框深度//框定安装面
  prop13: '两边大头',//装饰头/吊环
  prop14: '无',//石膏线
  prop15: '0',//房顶/石膏线到窗户上沿距离
  prop16: '',//窗幔尺寸
  prop17: '',//电源方向
  prop18: '',//电源方向
  pic: '[]',
  state: '',
  remark: '无',
  plasterLine: '无',
}
// const productType = ['罗马杆', '直轨', '电动直轨', '弯轨', '电动弯轨', '成品帘'];
const productType = ['罗马杆', '直轨', '电动直轨', '弯轨', '电动弯轨', '隐形轨道', '成品帘', '手动梦幻帘', '电动梦幻帘', '磁控百叶'];
const installation1 = ['顶装', '侧装', '已安装'];
const installation2 = ['内顶装', '外顶装', '内两头装', '外两头装', '外侧装', '内侧装', '内嵌式安装', '免打孔'];
const installation3 = ['内嵌式安装', '玻璃内框尺寸'];
const installation4 = ['顶装', '侧装', '两头装', '已安装'];
const installation5 = ['顶装', '已安装'];
class RelateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      currentData: { ...newData },
      width_remark: '',
      height_remark: '',
      index: 0,
      imcomingPorps: '',
      holder: '否',
    }
  }
  render() {
    const { visible, currentData, width_remark, height_remark, holder, } = this.state;
    return (
      <Modal
        title='产品选择'
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        width={700}
        okText='确定'
        cancelText='取消'
      >
        <div className='row-l vertical-lt margin-b10'>
          <div className='w90'>建议产品：</div>
          <Radio.Group value={currentData.prop1} onChange={this.changeProps.bind(this, 'prop1')} className='w500'>
            {
              productType.map((item, index) => {
                return (
                  <Radio value={item} key={index}>{item}</Radio>
                );
              })
            }
          </Radio.Group>
        </div>
        <div className='row-l vertical-lt margin-b10'>
          <div className='w90'>安装方式：</div>
          <Radio.Group value={currentData.prop2} onChange={this.changeProps.bind(this, 'prop2')} className='w500'>
            {
              currentData.prop1 === '成品帘' &&
              installation2.map((item, index) => {
                return (
                  <Radio value={item} key={index}>{item}</Radio>
                );
              })
            }
            {
              currentData.prop1 === '磁控百叶' &&
              installation3.map((item, index) => {
                return (
                  <Radio value={item} key={index}>{item}</Radio>
                );
              })
            }
            {
              currentData.prop1 === '罗马杆' &&
              installation4.map((item, index) => {
                return (
                  <Radio value={item} key={index}>{item}</Radio>
                );
              })
            }
            {
              currentData.prop1 === '隐形轨道' &&
              installation5.map((item, index) => {
                return (
                  <Radio value={item} key={index}>{item}</Radio>
                );
              })
            }

            {
              ['直轨', '电动直轨', '弯轨', '电动弯轨', '手动梦幻帘', '电动梦幻帘'].includes(currentData.prop1) &&
              installation1.map((item, index) => {
                return (
                  <Radio value={item} key={index}>{item}</Radio>
                );
              })
            }
          </Radio.Group>
        </div>
        <div className='w90'>宽度说明：</div>
        <div className='row-w margin-b10'>
          {
            this.getExplain(currentData).widthExplain.map((item, index) => {
              return (
                <div
                  onClick={this.changeProps.bind(this, 'width_remark', item)}
                  className={`${styles['menu']} ${styles[currentData.width_remark === item ? 'select2' : 'select1']} cursor-pointer`}
                  key={index}
                >{item}</div>
              );
            })
          }
          {currentData.width_remark === '自定义' &&
            <Input value={width_remark} onChange={this.changeInput.bind(this, 'width')} placeholder='自定义宽度说明' />
          }
        </div>
        <div className='w90'>高度说明：</div>
        <div className='row-w margin-b10'>
          {
            this.getExplain(currentData).heightExplain.map((item, index) => {
              return (
                <div
                  onClick={this.changeProps.bind(this, 'height_remark', item)}
                  className={`${styles['menu']} ${styles[currentData.height_remark === item ? 'select2' : 'select1']} cursor-pointer`}
                  key={index}
                >{item}</div>
              );
            })
          }
          {currentData.height_remark === '自定义' &&
            <Input value={height_remark} onChange={this.changeInput.bind(this, 'height')} placeholder='自定义高度说明' />
          }
        </div>
        {
          currentData.prop1 === '成品帘' &&
          <div className='row-l'>
            <div>拉绳：</div>
            <Radio.Group value={currentData.prop5} onChange={this.changeProps.bind(this, 'prop5')}>
              <Radio value='和客户沟通'>和客户沟通</Radio>
              <Radio value='左'>左</Radio>
              <Radio value='右'>右</Radio>
            </Radio.Group>
          </div>
        }
        {
          currentData.prop1 + currentData.prop2 === '罗马杆侧装' &&
          <div className='row-l'>
            <div>旋转支架：</div>
            <Radio.Group value={holder} onChange={this.changeHolder}>
              <Radio value='是'>是</Radio>
              <Radio value='否'>否</Radio>
            </Radio.Group>
          </div>
        }
      </Modal>
    );
  }
  //获取对应宽度、高度说明
  getExplain = (e) => {
    let widthExplain = [];
    let heightExplain = [];
    if (e.prop1 + e.prop2 === '罗马杆顶装') {
      widthExplain = ['满墙', '窗框两边已各加30公分', '罗马杆净杆', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '打孔成品尺寸', '挂钩成品尺寸', '自定义']
    } else if (['罗马杆侧装', '罗马杆两头装'].includes(e.prop1 + e.prop2)) {
      widthExplain = ['满墙', '窗框两边已各加30公分', '罗马杆净杆', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '打孔成品尺寸', '挂钩成品尺寸', '自定义']
    } else if (e.prop1 + e.prop2 === '罗马杆已安装') {
      widthExplain = ['罗马杆含头', '罗马杆净杆', '自定义']
      heightExplain = ['罗马杆杆上沿到地面', '罗马杆杆上沿到台面', '罗马杆挂环下沿到地面', '罗马杆挂环下沿到台面', '打孔成品尺寸', '挂钩成品尺寸', '自定义']
    } else if (['直轨顶装', '电动直轨顶装', '隐形轨道顶装'].includes(e.prop1 + e.prop2)) {
      widthExplain = ['满墙', '窗帘盒长', '飘窗满墙宽', '内框宽度', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '窗框顶到地面', '窗框顶到台面', '飘窗顶到飘窗台面', '挂钩成品尺寸', '自定义']
    } else if (e.prop1 + e.prop2 === '直轨侧装' || e.prop1 + e.prop2 === '电动直轨侧装') {
      widthExplain = ['满墙', '客户指定宽度', '直轨含头尺寸', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '挂钩成品尺寸', '自定义']
    } else if (['直轨已安装', '电动直轨已安装', '隐形轨道已安装'].includes(e.prop1 + e.prop2)) {
      widthExplain = ['直轨含头尺寸', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '挂钩下沿到地面', '挂钩下沿到台面', '挂钩下沿到客户指定位置', '挂钩成品尺寸', '自定义']
    } else if (e.prop1 + e.prop2 === '弯轨顶装' || e.prop1 + e.prop2 === '电动弯轨顶装') {
      widthExplain = ['窗帘盒长', 'L型飘窗总长', 'U型飘窗总长', '异型窗总长', 'L型窗帘盒总长', 'U型窗帘盒总长', '异型窗帘盒总长', '弯轨含头尺寸', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '窗框顶到地面', '窗框顶到台面', '飘窗顶到飘窗台面', '挂钩成品尺寸', '自定义']
    } else if (e.prop1 + e.prop2 === '弯轨侧装' || e.prop1 + e.prop2 === '电动弯轨侧装') {
      widthExplain = ['L型飘窗总长', 'U型飘窗总长', '弧形窗户总长', '不规则窗帘总长', '弯轨含头尺寸', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '窗框顶到地面', '窗框顶到台面', '挂钩成品尺寸', '自定义']
    } else if (e.prop1 + e.prop2 === '弯轨已安装' || e.prop1 + e.prop2 === '电动弯轨已安装') {
      widthExplain = ['弯轨含头尺寸', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '挂钩下沿到地面', '挂钩下沿到台面', '挂钩下沿到客户指定位置', '挂钩成品尺寸', '自定义']
    } else if (e.prop1 + e.prop2 === '手动梦幻帘顶装' || e.prop1 + e.prop2 === '电动梦幻帘顶装') {
      widthExplain = ['窗帘盒长', '满墙', '飘窗满墙宽', '内框宽度', '自定义']
      heightExplain = ['盒顶到地面', '盒顶到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '窗框顶到地面', '窗框顶到台面', '飘窗顶到飘窗台面', '挂珠成品尺寸', '自定义']
    } else if (e.prop1 + e.prop2 === '手动梦幻帘侧装' || e.prop1 + e.prop2 === '电动梦幻帘侧装') {
      widthExplain = ['满墙', '客户指定宽度', '直轨含头尺寸', '自定义']
      heightExplain = ['石膏线下到地面', '石膏线下到台面', '吊顶到地面', '吊顶到台面', '房顶到地面', '房顶到台面', '挂珠成品尺寸', '自定义']
    } else if (e.prop1 + e.prop2 === '手动梦幻帘已安装' || e.prop1 + e.prop2 === '电动梦幻帘已安装') {
      widthExplain = ['直轨含头尺寸', '自定义']
      heightExplain = ['挂珠下沿到地面', '挂珠下沿到台面', '挂珠下沿到客户指定位置', '挂珠成品尺寸', '自定义']
    } else if (e.prop1 === '成品帘') {
      widthExplain = ['内框宽度', '成品尺寸', '自定义']
      heightExplain = ['内框高度', '成品尺寸', '自定义']
    } else if (e.prop1 === '磁控百叶') {
      widthExplain = ['玻璃内框宽度', '玻璃宽度', '成品尺寸', '自定义']
      heightExplain = ['玻璃内框高度', '玻璃高度', '成品尺寸', '自定义']
    }
    return {
      widthExplain, heightExplain
    }
  }
  changeHolder = (e) => {
    this.setState({
      holder: e.target.value
    });
  }
  changeProps = (props, e) => {
    let currentData = { ...this.state.currentData };
    if (props === 'width_remark' || props === 'height_remark') {
      currentData[props] = e;
    } else {
      currentData[props] = e.target.value;
    }
    if (props === 'prop1') {
      currentData.prop13 = '';
      if (e.target.value === '成品帘') {
        currentData.prop2 = installation2[0];
        currentData.prop5 = '和客户沟通';
      } else if (e.target.value === '罗马杆') {
        currentData.prop2 = installation1[1];
        currentData.prop5 = '和客户沟通';
        currentData.prop13 = '两边大头';
      } else if (e.target.value === '磁控百叶') {
        currentData.prop2 = installation3[0];
        currentData.prop5 = '和客户沟通';
      } else if (e.target.value === '隐形轨道') {
        currentData.prop2 = installation5[0];
        currentData.prop5 = '和客户沟通';
      } else {
        currentData.prop2 = installation1[0];
        currentData.prop5 = '和客户沟通';
      }

      let { widthExplain, heightExplain } = this.getExplain(currentData);
      if (['直轨顶装', '电动直轨顶装', '隐形轨道顶装'].includes(currentData.prop1 + currentData.prop2)) {
        currentData.width_remark = widthExplain[1];
        currentData.height_remark = heightExplain[0];
      } else {
        currentData.width_remark = widthExplain[0];
        currentData.height_remark = heightExplain[0];
      }
    } else if (props === 'prop2') {
      let { widthExplain, heightExplain } = this.getExplain(currentData);
      currentData.width_remark = widthExplain[0];
      currentData.height_remark = heightExplain[0];
      currentData.prop13 = '';//修改旧数据
      if (currentData.prop1 === '罗马杆') {
        if (e.target.value === '已安装') {
          currentData.prop13 = '无吊环';
        } else if (e.target.value === '两头装') {
          currentData.prop13 = '';
        } else {
          currentData.prop13 = '两边大头';
        }
      }
    }
    this.setState({
      currentData,
    });
  }
  changeInput = (type, e) => {
    if (type === 'height') {
      this.setState({
        height_remark: e.target.value
      })
    } else {
      this.setState({
        width_remark: e.target.value
      })
    }
  }
  onShow = (data, index, props) => {
    // 'props'传入参数
    //index 在数组位置
    //data 值
    let holder = '否';
    let currentData = data;
    let { widthExplain, heightExplain } = this.getExplain(data);
    let widthIndex = widthExplain.indexOf(data.width_remark);
    let heightIndex = heightExplain.indexOf(data.height_remark);
    let width_remark = '';
    let height_remark = '';
    if (widthIndex < 0) {
      width_remark = data.width_remark;
      data.width_remark = '自定义';
    }
    if (heightIndex < 0) {
      height_remark = data.height_remark;
      data.height_remark = '自定义';
    }
    if ((currentData.prop1 + currentData.prop2).includes('罗马杆侧装')) {
      let prop2res = currentData.prop2.split('|');
      currentData.prop2 = prop2res[0];
      holder = prop2res[1] ? prop2res[1] : '否';
    }
    this.setState({
      visible: true,
      currentData,
      index,
      imcomingPorps: props,
      width_remark, height_remark,
      holder,
    });
  }
  onCancel = () => {
    this.setState({
      visible: false,
      holder: '否',
    });
  }
  onOk = () => {
    const { width_remark, height_remark, currentData, index, holder } = this.state;
    let updateData = { ...currentData };
    if (updateData.width_remark === '自定义') {
      updateData.width_remark = width_remark;
    }
    if (updateData.height_remark === '自定义') {
      updateData.height_remark = height_remark;
    }
    if (updateData.prop1 + updateData.prop2 === '罗马杆侧装') {
      updateData.prop2 = updateData.prop2 + '|' + holder;
    }
    this.props.updateTable(updateData, index);
    this.onCancel()
  }
}
export default RelateModal;