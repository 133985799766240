// import React, { useState, useImperativeHandle, useEffect } from 'react';
import React, { Component } from 'react';
import { Modal, Upload, Radio, Image, Button, message, Input, Table, Spin, InputNumber, Popconfirm } from 'antd';
import { getOrder, putOrder, getCategory, getPrice, getOrderRecord } from '../../../services/orderCore';
import { getShopVideos, getVideoInfo, getVideoPlay, getVideoSub, } from '../../../services/newOder';
import { ossUpload, } from '../../../utils/ossUpload.js';
import convert from '../../../utils/convert';
import Regin from '../../../components/regioRegister';
import styles from './index.module.css';
import _ from 'lodash';
import { DeleteOutlined, EyeOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getDepartment_Data } from '../../../config/storage';


const serviceDatas = {
  sort: 0,
  id: 0,
  order: 0,
  room: '窗户1',
  width: '1',
  width_remark: '',
  height: '0',
  height_remark: '',
  prop1: '布帘',
  prop2: '1',
  prop3: '',
  prop4: '',
  prop5: '',
  prop6: '',
  prop7: '',
  prop8: '',
  prop9: '',
  prop10: '',
  prop11: '',
  prop12: '',
  prop13: '',
  prop14: '',
  prop15: '',
  state: '已确认',
  pic: '',
  remark: '',
}

const modals = ['订单详情', '修改记录', '售后记录', '服务记录'];
const roomType = ['主卧', '次卧', '客厅', '厨房', '客餐厅', '阳台', '书房', '卫生间', '儿童房', '餐厅', '保姆房', '商务年会'];
const fastremark = ['远程', '超出订单追加费用', '超高', '串钩费', '特殊墙体', '拆旧'];

class IndexModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleName: false,
      spinningTable: false,
      spinning: false,
      id: 0,
      specialPrice: 0,
      data: {
        province: '北京市',
        city: '北京市',
        district: '东城区',
        add_app: 0,
        add_oms: 0,
        price_app: 0,
        price_oms: 0,
      },
      dataIndex: {},
      datas: {},
      type: '测量',
      postData: [],
      tbRemark: '',
      customTime: '',
      customRemark: '',
      videoList: [],
      selectVideo: [],
      videoPlay: [],

      groupType: [],
      detailType: [],
      detailID: [],
      // detailAdd: [],
      optionsRadio: [],
      orderPrice: [],
      orderPrice_oms: [],
      shopPrice: 0,
      workerPrice: 0,
      price_app: 0,

      modalIndex: 0,
      roomModalValue: '',
      roomModalValue: '窗帘1',

      modalType: '订单详情',
      modalsItem: '订单详情',
      columns: [],
      columnsDetails: [],
      serviceItemG: [],
      fixedPrice: '否',
      editLoading: false,
      appraiseFee: {
        bonus_order: '否',
        shop: 0,
        work: 0,
      },
      recordData: {
        create_time: '',
        allot_time: '',
        contact_time: '',
        app_gap: '',
        changeVisit: '',
        appoint_time: '',
        sign_time: '',
        service_time: '',
        installRatio: '',
      },
    }

    //订单详情
    //修改记录
    this.columnModify = [
      {
        title: '修改人员',
        align: 'center',
        dataIndex: 'name',
        width: 200,
        render: (text, record, index) => {
          return (<div>{record.ascription} ：{record.name}</div>);
        }
      },
      {
        title: '修改时间',
        align: 'center',
        dataIndex: 'submit_time',
        width: 200,
      },
      {
        title: '修改内容',
        align: 'center',
        dataIndex: 'content',
        width: 600,
      }
    ]
    //售后记录
    this.columnService = [
      {
        title: '售后类型',
        align: 'center',
        dataIndex: 'order_type',
        width: 100,
      },
      {
        title: '售后原因',
        align: 'center',
        dataIndex: 'content',
        width: 100,
      },
      {
        title: '金额',
        align: 'center',
        dataIndex: 'price_app',
        width: 100,
      },
      {
        title: '提交时间',
        align: 'center',
        dataIndex: 'submit_time',
        width: 100,
      },
      {
        title: '状态',
        align: 'center',
        dataIndex: 'state',
        width: 100,
      },
    ]
    //费用修改记录

    this.baseUrl = getDepartment_Data().service;
    this.shopID = getDepartment_Data().shopId
  }
  render() {
    const { visible, modalType, modalsItem, data, type, spinningTable, spinning, postData,
      roomModalValue, visibleName, columns, columnsDetails, editLoading, tbRemark,
      videoList, selectVideo, videoPlay, appraiseFee, recordData, } = this.state;
    return (
      <Modal
        title={modalType}
        width={1600}
        visible={visible}
        onCancel={this.onCancel}
        onOk={this.onOk}
        okText='确定'
        cancelText='取消'
        footer={
          <div className='row-r'>
            {
              modalType === '订单详情' ?
                <Button onClick={this.onCancel}>关闭</Button>
                :
                <div>
                  <Button onClick={this.onCancel}>取消</Button>
                  <Button onClick={this.onOk} type='primary' loading={editLoading}>确定</Button>
                </div>
            }
          </div>
        }
      >
        <Spin tip="数据加载中..." spinning={spinning}>
          {
            modalType === '订单详情' ? (
              <div>
                <div className='row-l'>
                  {
                    modals.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`${modalsItem === item ? styles['modals_choice_style'] : styles['modals_style']}`}
                          onClick={this.onModalsClick.bind(this, item)}
                        >
                          {item}
                        </div>
                      )
                    })
                  }
                </div>
                {
                  modalsItem === '订单详情' &&
                  <div>
                    <div className='row-l margin-t10'>
                      <div className={`${styles['order_details_style']}`}>姓名: {data.name}</div>
                      <div className={`${styles['order_details_style']}`}>手机号: {data.phone}</div>
                      <div className={`${styles['order_details_style']}`}>地址: {data.province} {data.city}{data.district}{data.address}</div>
                    </div>
                    <div className='row-l margin-t10'>
                      <div className={`${styles['order_details_style']}`}>师傅: {data.workerName}</div>
                      <div className={`${styles['order_details_style']}`}>手机号: {data.workerPhone}</div>
                    </div>
                    <div className='row-l vertical-lt margin-t10'>
                      <div className='row-l vertical-lt'>
                        <div className='fz18'>商户费用：</div>
                        <div className='col-t vertical-lt w400'>
                          <div>费用：{data.price_app}</div>
                          <div>特殊费用：{data.add_app}</div>
                          <div className='row-l'>
                            <div>特殊费用备注：</div>
                            <div className='w300'>{data.add_remark}</div>
                          </div>
                          <div>总费用：{data.price_app + data.add_app}</div>
                        </div>
                      </div>
                      <div className='row-l vertical-lt'>
                        <div className='fz18'>师傅费用：</div>
                        <div className='col-t vertical-lt'>
                          <div>费用：{data.price_oms}</div>
                          <div>特殊费用：{data.add_oms}</div>
                          <div className='row-l'>
                            <div>特殊费用备注：</div>
                            <div className='w300'>{data.add_worker_remark}</div>
                          </div>
                          <div>总费用：{data.price_oms + data.add_oms}</div>
                        </div>
                      </div>
                      {
                        appraiseFee.bonus_order === '是' &&
                        <div className='row-l vertical-lt'>
                          <div className='fz18'>好评费用：</div>
                          <div className='col-t vertical-lt'>
                            <div>商家费用：{appraiseFee.shop}</div>
                            <div>师傅费用：{appraiseFee.work}</div>
                          </div>
                        </div>
                      }
                      <div className='row-l margin-l20'>
                        <div className='fz18'>奖惩费用：</div>
                        <div>师傅费用：{appraiseFee.workReward}</div>
                      </div>
                    </div>
                    <div className='col vertical-lt'>
                      <div className={`${styles['order_details_style']} ${styles['display_hide']} margin-t10`}>
                        师傅备注: {data.worker_remark && data.worker_remark.split('✱')[0] ? data.worker_remark.split('✱')[0] : ''}
                      </div>
                      <div className={`${styles['order_details_style']} ${styles['display_hide']} margin-t10`}>
                        特殊备注: {data.add_remark}
                      </div>
                    </div>
                    <div className='col vertical-lt margin-t10'>
                      {
                        data.pic_remark &&
                        <div className='row-l'>
                          <div className='fz16 w90'>图片备注：</div>
                          {
                            <div className='row-w w1300'>
                              {
                                typeof data.pic_remark === 'object' &&
                                data.pic_remark.map((item, index) => {
                                  return (
                                    <div key={index} className={`${styles['content']}`}>
                                      <div className={`row ${styles['pic-icon']}`}></div>
                                      <img
                                        alt="暂无图片"
                                        className={`${styles['pic2']}`}
                                        src={`${this.baseUrl}/upload/${item}`}
                                      />
                                      <div className={`row ${styles['content-div2']}`}>
                                        <EyeOutlined
                                          className={`${styles['pic-icon-E']}`}
                                          onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                                          theme="outlined"
                                          title="预览"
                                        />
                                      </div>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          }
                        </div>
                      }
                      {
                        selectVideo.length > 0 &&
                        <div className='row-l vertical-lt margin-t10'>
                          <div className='fz16 w90'>视频备注：</div>
                          <div className='col-t vertical-lt w900'>
                            <div className='row-w w900'>
                              {
                                videoList.map((item, index) => {
                                  return (
                                    <div className={`${styles['video']} ${selectVideo.includes(index) ? styles['video-select'] : ''} none-select`}
                                      key={index} >
                                      {item.name}
                                    </div>
                                  );
                                })
                              }
                            </div>
                            <div className='row-w vertical-lt w900'>
                              {
                                videoPlay.map((item, index) => {
                                  return (
                                    <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                      <div className={`${styles['content-video']}`}>
                                        <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                                      </div>
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                    {
                      data.pic && JSON.parse(data.pic).length > 0 && <div className='row-l'>
                        <div>安装效果:</div>
                        <div className='row-w margin-t5'>
                          {
                            JSON.parse(data.pic).map((item, indexs) => {
                              return (
                                <div key={indexs} className={'margin-r5'}>
                                  <Image src={`${this.baseUrl}/upload/${item}`} className={styles['picBox']} />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                      data.bonus_pic && data.bonus_pic.length > 5 && <div className='row-l'>
                        <div>好评图片:</div>
                        <div className='row-w margin-t5'>
                          {
                            JSON.parse(data.bonus_pic).map((item, indexs) => {
                              return (
                                <div key={indexs} className={'margin-r5'}>
                                  <Image src={`${this.baseUrl}/upload/${item}`} className={styles['picBox']} />
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                    }
                    {
                      data.service === '安装' && data.category !== '地板' && <div className='margin-t20'>
                        <Table
                          columns={columnsDetails}
                          dataSource={data.serviceDatas}
                          size={'small'}
                          rowKey={record => record.id + Math.random()}
                          bordered
                          pagination={false}
                        />
                      </div>
                    }
                  </div>
                }
                {
                  modalsItem === '修改记录' && <div className='margin-t10'>
                    <Table
                      columns={this.columnModify}
                      dataSource={data.serviceFollows}
                      size={'small'}
                      rowKey={record => record.id + Math.random()}
                      bordered
                      pagination={false}
                    />
                  </div>
                }
                {
                  modalsItem === '售后记录' && <div className='margin-t10'>
                    <Table
                      columns={this.columnService}
                      dataSource={data.serviceAfterSales}
                      size={'small'}
                      rowKey={record => record.id + Math.random()}
                      bordered
                      pagination={false}
                    />
                  </div>
                }
                {
                  modalsItem === '服务记录' && <div className='margin-t10'>
                    <table>
                      <tbody className={styles['cell']}>
                        <tr className={styles['trd']}>
                          <td className={styles['trd']}>下单时间</td>
                          <td className={styles['trd']}>{recordData.create_time}</td>
                          <td className={styles['trd']}>分配时间</td>
                          <td className={styles['trd']}>{recordData.allot_time}</td>
                        </tr>
                        <tr>
                          <td className={styles['trd']}>预约操作时间</td>
                          <td className={styles['trd']}>{recordData.contact_time}</td>
                          <td className={styles['trd']}>是否在3小时内预约</td>
                          <td className={styles['trd']}>{recordData.app_gap}</td>
                        </tr>
                        <tr>
                          <td className={styles['trd']}>更改上门时间操作显示</td>
                          <td className={styles['trd']}>{recordData.changeVisit}</td>
                          <td className={styles['trd']}></td>
                          <td className={styles['trd']}></td>
                        </tr>
                        <tr>
                          <td className={styles['trd']}>预约上门时间</td>
                          <td className={styles['trd']}>{recordData.appoint_time}</td>
                          <td className={styles['trd']}></td>
                          <td className={styles['trd']}></td>
                        </tr>
                        <tr>
                          <td className={styles['trd']}>签到时间</td>
                          <td className={styles['trd']}>{recordData.sign_time}</td>
                          <td className={styles['trd']}>是否准时签到</td>
                          <td className={styles['trd']}>{recordData.signIntime}</td>
                        </tr>
                        <tr>
                          <td className={styles['trd']}>（测量单）数据上传时间</td>
                          <td className={styles['trd']}>{data.service === '测量' ? recordData.service_time : ''}</td>
                          <td className={styles['trd']}>是否在3小时内上传</td>
                          <td className={styles['trd']}>{data.service === '测量' ? recordData.is3hReturn : ''}</td>
                          {/* <td className={styles['trd']}>回传时效</td>
                          <td className={styles['trd']}>{data.service === '测量' ? recordData.returnRatio : ''}</td> */}
                        </tr>
                        <tr>
                          <td className={styles['trd']}>（测量单）12小时上门</td>
                          <td className={styles['trd']}>{data.service === '测量' ? recordData.is12hReturn : ''}</td>
                          <td className={styles['trd']}>（测量单）24小时上门</td>
                          <td className={styles['trd']}>{data.service === '测量' ? recordData.is24hReturn : ''}</td>
                        </tr>
                        <tr>
                          <td className={styles['trd']}>安装上传效果图时间</td>
                          <td className={styles['trd']}>{data.service === '安装' ? recordData.service_time : ''}</td>
                          <td className={styles['trd']}>是否在3小时内上传</td>
                          <td className={styles['trd']}>{data.service === '安装' ? recordData.is3hReturn : ''}</td>
                          {/* <td className={styles['trd']}>安装时效</td>
                          <td className={styles['trd']}>{data.service === '安装' ? recordData.returnRatio : ''}</td> */}
                        </tr>
                        <tr>
                          <td className={styles['trd']}>（安装单）48小时上门</td>
                          <td className={styles['trd']}>{data.service === '安装' ? recordData.is48hReturn : ''}</td>
                          <td className={styles['trd']}></td>
                          <td className={styles['trd']}></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                }
              </div>
            ) : (
              <div>
                <div className='row-l'>
                  <div>
                    <div className='row-l margin-b10'>
                      <div className='margin-r10'>
                        客户姓名:
                      </div>
                      <Input placeholder='请输入客户名字' className='w295' value={data.name} onChange={this.onDataChange.bind(this, 'name')} />
                    </div>
                    <div className='row-l margin-b10'>
                      <div className='margin-r10'>
                        客户手机:
                      </div>
                      <Input placeholder='请输入客户手机' className='w295' value={data.phone} onChange={this.onDataChange.bind(this, 'phone')} />
                    </div>
                    <div className='row-l vertical-lt margin-b10'>
                      <div className='margin-r10 w70'>
                        客户地址:
                      </div>
                      {/* <Input placeholder='' className='w295'/> */}
                      <Regin value={[data.province, data.city, data.district]} onChange={this.onReginChange} />
                    </div>
                    <div className='row-l margin-b10'>
                      <div className='margin-r10 w70'>
                        详细地址:
                      </div>
                      <Input.TextArea autoComplete="off" rows={3} className='w500' value={data.address} onChange={this.onDataChange.bind(this, 'address')} />
                    </div>
                  </div>
                  <div className={styles['priceChange']}>
                    {
                      data.serviceFollows && data.serviceFollows.length > 0 ?
                        <Table
                          columns={this.columnModify}
                          rowKey={record => Math.random()}
                          dataSource={data.serviceFollows}
                          // dataSource={data.serviceFollows.filter(item => item.type === '修改')}
                          // dataSource={data.serviceFollows && data.serviceFollows.map(item => {
                          //   if (item.type === '修改') {
                          //     console.log(item)
                          //     return item;
                          //   }
                          // })}
                          size={'small'}
                          bordered
                          pagination={{ defaultPageSize: 4 }}
                        />
                        : ''
                    }
                  </div>
                </div>
                <div>
                  {type === '安装' && data.category !== '其他' && data.category !== '地板'
                    && data.type !== '二次上门' && data.type !== '售后订单' &&
                    <div className='w100 margin-t20'>
                      <div className='margin-r10'>安装信息:</div>
                      <Spin tip="数据加载中..." spinning={spinningTable}>
                        <Table
                          columns={columns}
                          dataSource={postData}
                          size={'small'}
                          rowKey={record => record.id + Math.random()}
                          bordered
                          pagination={false}
                        />
                      </Spin>
                      <div className={`${styles['top-right-empty']} margin-b20`} onClick={this.onAddData}><PlusCircleOutlined /> 新增产品</div>
                    </div>}
                  <div className='col vertical-lt margin-t10'>
                    <div className='margin-r10 '>
                      订单备注:
                    </div>
                    <Input.TextArea value={data.shop_remark} rows={4} placeholder="填写订单备注信息" style={{ width: 1200 }} onChange={this.onShop_remark.bind(this, 'shop_remark')} />
                    <div className='margin-r10 '>
                      唯一订单号:
                    </div>
                    <Input.TextArea value={tbRemark} rows={2} placeholder="填写唯一订单号" style={{ width: 600 }} onChange={this.onTb_remark} />
                  </div>
                  <div className='col vertical-lt w100 margin-b10 margin-t10'>
                    <div className='row-l w100 margin-t10'>
                      <div className={'margin-r10 w90'}>图片备注：</div>
                      <div className='row-w w100'>
                        <Upload
                          type='file'
                          beforeUpload={this.onAddPic}
                          fileList={[]}
                          accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
                          // accept='image/*'
                          multiple={true}
                        >
                          <div className={`row cursor-pointer ${styles['pic-div']}`}>
                            <div className="row h100">
                              <PlusOutlined />
                              <span>
                                新增图片
                              </span>
                            </div>
                          </div>
                        </Upload>
                        {
                          data.pic_remark && typeof data.pic_remark === 'object' &&
                          data.pic_remark.map((item, index) => {
                            return (
                              <div key={index} className={`${styles['content']}`}>
                                <div className={`row ${styles['pic-icon']}`}
                                  onClick={this.delSignPic.bind(this, index)}
                                >
                                  <DeleteOutlined />
                                </div>
                                <img
                                  alt="暂无图片"
                                  className={`${styles['pic']}`}
                                  src={`${this.baseUrl}/upload/${item}`}
                                />
                                <div className={`row ${styles['content-div']}`}>
                                  <EyeOutlined
                                    className={`${styles['pic-icon-E']}`}
                                    onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                                    theme="outlined"
                                    title="预览"
                                  />
                                </div>
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                    <div className='row-l vertical-lt w100 margin-t10'>
                      <div className={'margin-r10 w90'}>视频备注：</div>
                      <div className='col-t vertical-lt w100'>
                        <div className='row-w w900'>
                          {
                            videoList.map((item, index) => {
                              return (
                                <div className={`${styles['video']} ${selectVideo.includes(index) ? styles['video-select'] : ''} none-select`}
                                  key={index} onClick={this.selectVideo.bind(this, index)}>
                                  {item.name}
                                </div>
                              );
                            })
                          }
                        </div>
                        <div className='row-l vertical-lt w100'>
                          <Upload
                            type='file'
                            beforeUpload={this.uploadVideo}
                            customRequest={() => { }}
                            fileList={[]}
                            multiple={false}
                            maxCount={1}
                          >
                            <div className={`row cursor-pointer ${styles['pic-div']}`}>
                              <div className="row h100">
                                <PlusOutlined />
                                <span>
                                  新增视频
                                </span>
                              </div>
                            </div>
                          </Upload>
                          <div className='row-w w100'>
                            {
                              videoPlay.map((item, index) => {
                                return (
                                  <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                                    <div className={`${styles['content-video']}`}>
                                      <video controls='controls' className={styles['content-image']} src={item.videoUrl} />
                                    </div>
                                    <Popconfirm
                                      title="您确定要删除该视频吗?"
                                      onConfirm={this.delVideo.bind(this, index)}
                                      okText="确认"
                                      cancelText="取消"
                                    >
                                      <Button type='danger' className='w100 margin-t5'>删除视频</Button>
                                    </Popconfirm>
                                  </div>
                                );
                              })
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='row-l back-color0 w100 margin-t10 padding20 vertical-lt'>
                    <div className='col vertical-lt'>
                      <div className='row-l margin-t10'>
                        <span className={styles['title-order-cost']}>商户费用:</span>
                        <div>
                          <span className='color2 fz20'>
                            {type === '安装' && data.category !== '地板'
                              && data.category !== '其他'
                              && data.type !== '二次上门'
                              && data.type !== '售后订单'
                              ? this.getAllPrices() :
                              this.getPrices_app(data.price_app)}
                            {/* this.state.price_app  */}
                          </span>元
                        </div>
                      </div>
                      <div className='row-l margin-t10 vertical-lt'>
                        <span className={styles['title-order-cost']}>商户特殊费用:</span>
                        <InputNumber
                          value={data.add_app}
                          addonAfter="元"
                          style={{ width: 400 }}
                          // min={0}
                          onChange={this.onSpecialChange.bind(this, 'add_app')}
                        />
                        <div className='color5 margin-l10'> (添加后,将被加至总费用金额中)</div>
                      </div>
                      <div className='row-l margin-t10'>
                        <span className={styles['title-order-cost']}>商户特殊费用备注:</span>
                        <Input.TextArea value={data.add_remark} rows={4} placeholder="填写相关特殊费用备注信息" style={{ width: 400 }} onChange={this.onAppRemark.bind(this)} />
                      </div>
                      <div className='row-l margin-t10'>
                        <span className={styles['title-order-cost']}>商户总费用:</span>
                        <div>
                          <span className='color2 fz20'>
                            {type === '安装' && data.category !== '地板'
                              && data.category !== '其他'
                              && data.type !== '二次上门'
                              && data.type !== '售后订单'
                              ? this.getAllPrices() + data.add_app :
                              this.getPrices_app(data.price_app) + data.add_app
                            }
                          </span>元
                        </div>
                      </div>
                    </div>
                    <div>
                      {/* <div className='row-sb w100'>
                        <div className={styles['top-left-title']}>师傅费用</div>
                      </div> */}
                      <div className='row-l margin-t10'>
                        <span className={styles['title-order-cost']}>师傅费用:</span>
                        <div>
                          <span className='color2 fz20'>
                            {type === '安装' && data.category !== '地板'
                              && data.category !== '其他'
                              && data.type !== '二次上门'
                              && data.type !== '售后订单'
                              ? this.getAllPricesOms() : this.getPrices_oms(data.price_oms)}
                          </span>元
                        </div>
                      </div>
                      <div className='row-l margin-t10'>
                        <span className={styles['title-order-cost']}>师傅特殊费用:</span>
                        <InputNumber
                          value={data.add_oms}
                          addonAfter="元"
                          style={{ width: 400 }}
                          // min={0}
                          onChange={this.onSpecialChange.bind(this, 'add_oms')}
                        />
                      </div>
                      <div className='row-l margin-t10'>
                        <span className={styles['title-order-cost']}>师傅特殊费用备注:</span>
                        <Input.TextArea value={data.add_worker_remark} rows={4} placeholder="填写相关特殊费用备注信息" style={{ width: 400 }} onChange={this.onOmsRemark.bind(this)} />
                        <div className='col-t vertical-lt margin-l10'>
                          {
                            fastremark.map((fitem, findex) => {
                              return (
                                <div onClick={this.fastRemark.bind(this, fitem)} key={findex} className={styles['fast-remark']}>{fitem}</div>
                              );
                            })
                          }

                        </div>
                      </div>
                      <div className='row-l margin-t10'>
                        <span className={styles['title-order-cost']}>师傅总费用:</span>
                        <div>
                          <span className='color2 fz20'>
                            {/* {data.price_oms + data.add_oms} */}
                            {type === '安装' && data.category !== '地板'
                              && data.category !== '其他'
                              && data.type !== '二次上门'
                              && data.type !== '售后订单'
                              ? this.getAllPricesOms() + data.add_oms : this.getPrices_oms(data.price_oms) + data.add_oms}
                          </span>元
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
        </Spin>
        <Modal
          title={'修改窗帘名称'}
          visible={visibleName}
          width={700}
          onCancel={this.onCancelName}
          onOk={this.onOkName}
          okText='确定'
          cancelText='取消'
        >
          <div>
            <Input className='margin-b10' value={roomModalValue} onChange={this.onModalValueChange} />
            <div className='row-w'>
              {
                roomType.map((item, index) => {
                  return (
                    <div key={index} className={styles['room_type_div']} onClick={this.onRoomModalValue.bind(this, item)}>{item}</div>
                  )
                })
              }
            </div>
          </div>
        </Modal>
      </Modal>
    );
  }

  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  selectVideo = (e) => {
    let selectVideo = [...this.state.selectVideo];
    if (selectVideo.includes(e)) {
      selectVideo = selectVideo.filter(r => r !== e);
    } else {
      selectVideo.push(e);
    }
    this.setState({
      selectVideo
    });
  }
  //图片备注
  onAddPic = async (file, fileList) => {
    let data = _.cloneDeep(this.state.data)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = data.pic_remark.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      data.pic_remark[leng + i] = name;
    }
    this.setState({
      data
    })
  }
  delSignPic = (e) => {
    let data = _.cloneDeep(this.state.data)
    data.pic_remark.splice(e, 1);
    this.setState({
      data,
    });
  }
  //视频备注
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let videoPlay = [...this.state.videoPlay]
      videoPlay.push({
        name: '新增视频',
        videoId: e,
        videoUrl: res.data.playURL,
        isVideo: true,
      });
      this.setState({
        videoPlay: videoPlay
      })
    }
  }
  delVideo = (index) => {
    let videoPlay = [...this.state.videoPlay]
    videoPlay.splice(index, 1)
    this.setState({
      videoPlay: videoPlay
    })
  }

  getPrices_oms = (price_oms) => {
    let type = this.state.data.type;
    if (type === '售后订单' || type === '二次上门') {
      return price_oms
    }
    return price_oms > this.state.workerPrice ? price_oms : this.state.workerPrice
  }
  getPrices_app = (price_app) => {
    let type = this.state.data.type;
    if (type === '售后订单' || type === '二次上门') {
      return price_app
    }
    return price_app > this.state.shopPrice ? price_app : this.state.shopPrice
  }

  //房间名称
  getRoomModal = (index, name) => {
    this.setState({
      visibleName: true,
      modalIndex: index,
      roomModalValue: name,
    })
  }
  onRoomModalValue = (e) => {
    this.setState({
      roomModalValue: e
    })
  }
  onModalValueChange = (e) => {
    this.setState({
      roomModalValue: e.target.value
    })
  }
  onOkName = () => {
    let { modalIndex, roomModalValue } = this.state
    this.getInputVaule('room', modalIndex, roomModalValue)
    this.onCancelName()
  }
  //关闭modal
  onCancelName = () => {
    this.setState({
      visibleName: false,
      modalIndex: 0,
    })
  }

  //切换modals
  onModalsClick = async (e) => {
    this.setState({
      modalsItem: e
    })
    if (e === '服务记录') {
      let res = await getOrderRecord(this.state.id);
      if (res.status === 200) {
        let recordData = {
          create_time: res.data.create_time,//下单时间
          allot_time: res.data.allot_time,//分配时间
          contact_time: res.data.contact_time !== '0001-01-01T00:00:00' ? res.data.contact_time : '',//预约操作时间
          app_gap: res.data.contact_time !== '0001-01-01T00:00:00' ? res.data.app_gap < 3 ? '是' : '否' : '',//是否在3小时内预约
          changeVisit: '',//更改上门时间操作显示
          appoint_time: res.data.appoint_time !== '0001-01-01T00:00:00' ? res.data.appoint_time : '',//预约上门时间
          sign_time: res.data.sign_time !== '0001-01-01T00:00:00' ? res.data.sign_time : '',//签到时间
          signIntime: '',//是否准时签到
          service_time: res.data.service_time !== '0001-01-01T00:00:00' ? res.data.service_time : '',//数据上传时间
          returnRatio: '',//回传时效
          installRatio: '',//安装时效
          is3hReturn: '',
          is12hReturn: '',
          is24hReturn: '',
          is48hReturn: '',
        };
        let changeData = res.data.serviceFollows.filter(r => r.content.includes('修改预约时间'));
        if (changeData[0]) {
          recordData.changeVisit = changeData[changeData.length - 1].submit_time + '：' + changeData[changeData.length - 1].content;
        }
        if (res.data.sign_time !== '0001-01-01T00:00:00') {
          let before = new Date(res.data.appoint_time).getTime() - 30 * 60 * 1000;
          let after = new Date(res.data.appoint_time).getTime() + 30 * 60 * 1000;
          let sign = new Date(res.data.sign_time).getTime();
          let start = new Date(res.data.create_time).getTime();
          if (before < sign && sign < after) {
            recordData.signIntime = '是';
          } else {
            recordData.signIntime = '否';
          }

          recordData.is12hReturn = Number((sign - start) / 1000 / 60 / 60) < 12 ? '是' : '否';
          recordData.is24hReturn = Number((sign - start) / 1000 / 60 / 60) < 24 ? '是' : '否';
          recordData.is48hReturn = Number((sign - start) / 1000 / 60 / 60) < 48 ? '是' : '否';
        }
        if (res.data.service_time !== '0001-01-01T00:00:00') {
          let start = new Date(res.data.sign_time).getTime();
          let end = new Date(res.data.service_time).getTime();
          recordData.returnRatio = Number((end - start) / 1000 / 60 / 60).toFixed(1) + 'h';
          recordData.is3hReturn = Number((end - start) / 1000 / 60 / 60) < 3 ? '是' : '否';
        }

        this.setState({
          recordData,
        })
      }
    }
  }


  //修改订单
  //省市区
  onReginChange = (e) => {
    let data = { ...this.state.data }
    data.province = e[0];
    data.city = e[1];
    data.district = e[2];
    this.setState({
      data: data
    })
  }
  //输入框
  onDataChange = (type, e) => {
    let data = { ...this.state.data }
    data[type] = e.target.value
    if (type === 'name') {
      data[type] = data[type].replace(/[^\w\u4e00-\u9fa5]/g, '');
    }
    this.setState({
      data: data
    })
  }
  //特殊备注(商户)
  onAppRemark = (e) => {
    let data = { ...this.state.data };
    data.add_remark = e.target.value;
    this.setState({
      data: data
    })
  }
  //特殊备注(师傅)
  onOmsRemark = (e) => {
    let data = { ...this.state.data };
    data.add_worker_remark = e.target.value;
    this.setState({
      data: data
    })
  }
  //订单备注
  onShop_remark = (type, e) => {
    let data = { ...this.state.data };
    data[type] = e.target.value;
    this.setState({
      data: data
    })
  }
  onTb_remark = (e) => {
    let tbRemark = e.target.value;
    tbRemark = tbRemark.replace(/[^\w\u4e00-\u9fa5]/g, '')
    this.setState({
      tbRemark
    });
  }
  //特殊费用
  onSpecialChange = (type, e) => {
    let data = { ...this.state.data };
    data[type] = e ? e : 0
    this.setState({
      data: data
    })
  }
  fastRemark = (e) => {
    let data = { ...this.state.data };
    let newRemark = data.add_worker_remark ? '；' + e : e;
    data.add_worker_remark = data.add_worker_remark + newRemark;
    this.setState({
      data
    });
  }
  //算价
  getPrices = async (index) => {
    this.setState({
      spinningTable: true,
    })
    const { type, optionsRadio, detailID, orderPrice, data, orderPrice_oms, serviceItemG } = this.state;
    let num = [];
    if (type !== '测量') {
      let newOptionsRadio = optionsRadio[index];
      for (let i in newOptionsRadio) {
        num.push(Number(newOptionsRadio[i].id))
      }
      if (this.state.data.category === '其他' || this.state.data.category === '地板') {
        num.push(measureID);
      } else {
        num.push(detailID[index]);
      }
    }
    let itemid = num.join('|');
    let rsp = await getPrice(itemid, data.id, data.shop);
    if (rsp.status !== 200) {
      message.error(res.data)
      return;
    };
    if (this.state.types === '窗帘') {
      serviceItemG[index] = rsp.data.serviceItems;
    }
    orderPrice[index] = rsp.data.price_app;
    orderPrice_oms[index] = rsp.data.price_oms;
    this.setState({
      orderPrice: orderPrice,
      orderPrice_oms: orderPrice_oms,
      spinningTable: false,
      serviceItemG: serviceItemG,
    });
  }
  //删除
  ondelData = (index) => {
    let postData = _.cloneDeep(this.state.postData);
    let orderPrice = _.cloneDeep(this.state.orderPrice);
    let optionsRadio = _.cloneDeep(this.state.optionsRadio);
    let groupType = _.cloneDeep(this.state.groupType);
    let detailType = _.cloneDeep(this.state.detailType);
    let orderPrice_oms = _.cloneDeep(this.state.orderPrice_oms);
    let serviceItemG = _.cloneDeep(this.state.serviceItemG);
    postData.splice(index, 1);
    orderPrice.splice(index, 1);
    optionsRadio.splice(index, 1);
    detailType.splice(index, 1);
    groupType.splice(index, 1);
    orderPrice_oms.splice(index, 1);
    serviceItemG.splice(index, 1);
    this.setState({
      postData: postData,
      orderPrice: orderPrice,
      optionsRadio: optionsRadio,
      serviceItemG: serviceItemG,
      groupType: groupType,
      detailType: detailType,
      orderPrice_oms: orderPrice_oms,
    })

  }
  //新增
  onAddData = () => {
    let postData = _.cloneDeep(this.state.postData);
    const { groupType, detailType, datas, detailID } = this.state;
    let newData = { ...serviceDatas };
    // newData.sort = postData.length;
    newData.order = this.state.data.id
    postData.push(newData);
    groupType.push(datas.grid[0].group)
    detailType.push(datas.grid[0].detail[0].name)
    detailID.push(datas.grid[0].detail[0].id)
    this.setState({
      postData: postData,
      groupType: groupType,
      detailType: detailType,
      detailID: detailID,
    }, () => this.getOptionsRadio(postData, this.state.datas.grid[0].detail[0].add, postData.length - 1))

  }
  //类型切换
  onClickDetail = (type, id, index, e) => {
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === type)[0].add;
    let postData = [...this.state.postData];
    postData[index].prop3 = JSON.stringify({ id: id, name: type });
    let detailType = [...this.state.detailType];
    let detailID = [...this.state.detailID];
    detailType[index] = type;
    detailID[index] = detail.filter(r => r.name === type)[0].id;
    this.setState({
      detailType: detailType,
      detailID: detailID,
    }, async () => this.getPrices(index));
    this.getOptionsRadio(postData, detailAdd, index)
  }
  //品类切换
  onClickGrid = (type, index) => {
    let postData = [...this.state.postData];
    let detail = this.state.datas.grid.filter(r => r.group === type)[0].detail;
    let detailAdd = detail[0].add;
    postData[index].prop1 = type;
    let groupType = [...this.state.groupType];
    groupType[index] = type
    let detailType = [...this.state.detailType];
    let detailID = [...this.state.detailID];
    detailType[index] = detail[0].name;
    detailID[index] = detail[0].id;
    this.setState({
      groupType: groupType,
      detailType: detailType,
      detailID: detailID,
    }, async () => this.getPrices(index));
    this.getOptionsRadio(postData, detailAdd, index)
  }
  //品类数据
  getOptionsRadio = (postData, detailAdd, index) => {
    let optionsRadio = [...this.state.optionsRadio];
    postData.forEach((pitem, pindex) => {
      let newOptionsRadio = {};
      detailAdd.forEach(ditem => {
        newOptionsRadio[ditem.group] = { id: ditem.options[0].id, name: ditem.options[0].name };
      });
      optionsRadio.splice(index, 1, newOptionsRadio)
    })
    this.setState({
      optionsRadio: optionsRadio,
    }, async () => this.changePostData(postData, index))
  }
  //table数据
  changePostData = (data, index) => {
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === this.state.detailType[index])[0].add;
    let prop3ID = detail.filter(r => r.name === this.state.detailType[index])[0].id
    const { groupType, detailType } = this.state;
    data[index].prop1 = groupType[index];
    data[index].prop3 = JSON.stringify({ id: prop3ID, name: detailType[index] });
    data[index].prop4 = '';
    data[index].prop5 = '';
    data[index].prop6 = '';
    data[index].prop7 = '';
    data[index].prop8 = '';
    data[index].prop9 = '';
    detailAdd.forEach(item => {
      switch (item.group) {
        case this.getCaseType(this.state.data.category, 'prop4'):
          data[index].prop4 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop5'):
          data[index].prop5 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop6'):
          data[index].prop6 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop7'):
          data[index].prop7 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop8'):
          data[index].prop8 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        case this.getCaseType(this.state.data.category, 'prop9'):
          data[index].prop9 = JSON.stringify({ id: item.options[0].id, name: item.options[0].name });
          break;
        default:
          break;
      }
    })
    this.setState({
      postData: data,
    }, async () => this.getPrices(index))
  }
  //table品类
  tableType = (itemType, index) => {
    if (JSON.stringify(this.state.datas) === '{}') {
      return;
    }
    if (!this.state.optionsRadio[index]) {
      return;
    }
    let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
    let detailAdd = detail.filter(r => r.name === this.state.detailType[index])[0].add;
    let options = detailAdd.filter(r => r.group === itemType);
    let optionsRadio = this.state.optionsRadio[index];
    return (
      <div className='col'>
        {
          options.length === 0 ? <div className={styles['options0']}></div> : <Radio.Group value={optionsRadio[itemType].id} className='row-w'>
            {
              options[0].options.map((oItem, oIndex) => {
                return (
                  <Radio value={oItem.id} key={oIndex} onChange={this.onChangeRadio.bind(this, oItem.name, oItem.id, itemType, index)}>{oItem.name}</Radio>
                )
              })
            }
          </Radio.Group>
        }
      </div>
    )
  }
  //table品类切换
  onChangeRadio = (name, id, type, index, e) => {
    let optionsRadio = [...this.state.optionsRadio];
    let postData = [...this.state.postData];
    optionsRadio[index][type] = { id, name };
    switch (type) {
      case this.getCaseType(this.state.data.category, 'prop4'):
        postData[index].prop4 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop5'):
        postData[index].prop5 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop6'):
        postData[index].prop6 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop7'):
        postData[index].prop7 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop8'):
        postData[index].prop8 = JSON.stringify({ id, name });
        break;
      case this.getCaseType(this.state.data.category, 'prop9'):
        postData[index].prop9 = JSON.stringify({ id, name });
        break;
      default:
        break;
    }
    this.setState({
      optionsRadio: optionsRadio,
      postData: postData,
    }, async () => this.getPrices(index))
  }
  //输入框数据
  getInputVaule = (type, index, e) => {
    let value;
    if (type === 'room') {
      value = e;
    } else if (type === 'prop2') {
      value = e.target.value ? e.target.value : '1';
    } else {
      value = e.target.value ? e.target.value : '1';

    }
    let postData = [...this.state.postData];
    postData[index][type] = value + '';
    this.setState({
      postData: postData,
    })
  }
  //打开modal
  onShowModal = (data, type) => {
    this.setState({
      visible: true,
      type: data.service,
      dataIndex: data,
      id: data.id,
      modalType: type,
    })
    this.getData(data.id, data.category, data.service, type);

  }
  //获取数据
  getData = async (id, e, type, modalType) => {
    this.setState({
      spinningTable: true,
      spinning: true,
    })
    let appraiseFee = {
      bonus_order: '否',
      shop: 0,
      work: 0,
      workReward: 0,
    };
    let tbRemark = '';
    let res = await getOrder(id)
    if (res.status !== 200) {
      message.error(res.data);
      this.onCancel();
      return;
    }
    if (res.data.pic_remark && res.data.pic_remark.includes('[') && res.data.pic_remark.includes(']')) {
      res.data.pic_remark = JSON.parse(res.data.pic_remark);
    } else {
      res.data.pic_remark = [];
    }
    appraiseFee.bonus_order = res.data.bonus_order;
    if (res.data.sFinances) {
      res.data.sFinances.map(item => {
        if (item.type === '好评') {
          appraiseFee.shop += item.price
        }
      })
    }
    if (res.data.wFinances) {
      res.data.wFinances.map(item => {
        if (item.type === '好评') {
          appraiseFee.work += item.price
        }
        if (item.type === '奖惩') {
          appraiseFee.workReward += item.price
        }
      })
    }
    appraiseFee.shop = Math.abs(appraiseFee.shop);
    appraiseFee.work = Math.abs(appraiseFee.work);

    let videoList = [];
    let videoPlay = [];
    // let result = await getShopVideos(res.data.shop);
    // if (result.status === 200) {
    // videoList = result.data ? JSON.parse(result.data) : [];
    // }
    let selectVideo = [];
    if (res.data.vod_remark && res.data.vod_remark.includes('[') && res.data.vod_remark.includes(']')) {
      res.data.vod_remark = JSON.parse(res.data.vod_remark);
    } else {
      res.data.vod_remark = [];
    }
    if (res.data.vod_remark.length > 0) {
      res.data.vod_remark.map(item => {
        // let exit = videoList.findIndex(r => r.name === item.name && r.videoId === item.videoId);
        // if (exit > -1) {
        //   selectVideo.push(exit);
        // }
        // if (item.isVideo) {
        videoPlay.push(item);
        // }
      })
      let that = this;
      videoPlay.map((item, index) => {
        (async () => {
          let local = index;
          let rsp = await getVideoPlay(item.videoId);
          if (rsp.status === 200) {
            videoPlay[local].videoUrl = rsp.data.playURL;
            that.setState({
              videoPlay
            });
          }
        })()
      })
    }
    let customTime = '';
    let customRemark = '';
    let remarkSum = res.data.shop_remark.split('✱');
    if (remarkSum.length === 3) {
      customTime = remarkSum[1] ? remarkSum[1] : '';
      customRemark = remarkSum[2] ? remarkSum[2] : '';
    }
    res.data.shop_remark = remarkSum[0]

    let sumName = res.data.name.split('DD');
    tbRemark = sumName[1] ? sumName[1] : '';
    res.data.name = res.data.name.split('DD')[0];
    this.setState({
      postData: res.data.serviceDatas,
      workerPrice: res.data.workerPrice,
      shopPrice: res.data.shopPrice,
      fixedPrice: res.data.fix_price,
      appraiseFee,
      tbRemark, customTime, customRemark,
      videoList, selectVideo, videoPlay,
    })
    this.getColumns(res.data.category);
    if (modalType !== '订单详情') {
      let rsp = await getCategory(e, res.data.shop);
      if (rsp.status !== 200) {
        message.error(res.data);
        this.onCancel();
        return;
      };
      let price_app = 0;
      if (type === '测量') {
        res.data.serviceItems.forEach(item => {
          price_app += item.price_app
        })
        let dataIndex = 0;
        rsp.data.map((ditem, dindex) => {
          if (ditem.type.includes('测量')) {
            dataIndex = dindex
          }
        })

        this.setState({
          datas: rsp.data[dataIndex],
          orderPrice: [res.data.price_app],
          price_app: price_app,
          data: res.data,
          spinningTable: false,
          spinning: false,
          postData: [],
        })
      } else {
        let groupType = [];
        let detailType = [];
        let detailID = [];
        let orderPrice = [];
        let orderPrice_oms = [];
        res.data.serviceItems.forEach(item => {
          if (!item.name.includes('测量')) {
            price_app += item.price_app
          }
        })
        let dataIndex = 0;
        rsp.data.map((ditem, dindex) => {
          if (ditem.type.includes('安装')) {
            dataIndex = dindex
          }
        })
        if (res.data.category === '地板' || res.data.category === '其他') {
          price_app = res.data.price_app
        }
        let serviceItemG = []
        res.data.serviceDatas.forEach(async (item, index) => {
          groupType.push(item.prop1);
          if (res.data.category !== '地板') {
            detailID.push(JSON.parse(item.prop3).id);
            detailType.push(JSON.parse(item.prop3).name);
          }
          if (res.data.category === '窗帘') {
            let ids = [];
            ids.push(JSON.parse(item.prop3).id)
            if (item.prop4) {
              ids.push(JSON.parse(item.prop4).id)
            }
            if (item.prop5) {
              ids.push(JSON.parse(item.prop5).id)
            }
            if (item.prop6) {
              ids.push(JSON.parse(item.prop6).id)
            }
            if (item.prop7) {
              ids.push(JSON.parse(item.prop7).id)
            }
            if (item.prop8) {
              ids.push(JSON.parse(item.prop8).id)
            }
            if (item.prop9) {
              ids.push(JSON.parse(item.prop9).id)
            }
            let itemid = ids.join('|');
            let result = await getPrice(itemid, res.data.worker, res.data.shop);
            if (result.status !== 200) {
              message.error(result.data)
            }
            let local = index;
            serviceItemG[local] = result.data.serviceItems;
            orderPrice[local] = result.data.price_app;
            orderPrice_oms[local] = result.data.price_oms;
            this.setState({
              orderPrice, orderPrice_oms,
              serviceItemG
            })
          } else if (res.data.category === '墙布墙纸' || res.data.category === '晾衣架') {
            orderPrice.push(item.prop8);
            orderPrice_oms.push(item.prop9)
          }
        })
        this.setState({
          datas: rsp.data[dataIndex],
          groupType: groupType,
          detailType: detailType,
          detailID: detailID,
          // detailAdd: rsp.data[1].grid[0].detail[0].add,
          postData: res.data.serviceDatas,
          orderPrice: orderPrice,
          orderPrice_oms: orderPrice_oms,
          price_app: price_app,
          data: res.data,
          spinningTable: false,
          spinning: false,
          // specialPrice: res.data.add_app,
        }, async () => this.getOptionsRadios());
      }
    } else {
      this.setState({
        data: res.data,
        spinning: false,
      })
    }
  }

  //获取表格
  getColumns = (type) => {
    let columns = [];
    let columnsDetails = [];
    if (type === '窗帘') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
            )
          }
        },
        {
          title: '长度（米）',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={0} defaultValue={record.width} onBlur={this.getInputVaule.bind(this, 'width', index)} />
            )
          }
        },
        {
          title: '品类',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            let grid = this.state.datas.grid;
            let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '层数',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('层数', index)
            )
          }
        },
        {
          title: '四角钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('四角钩', index)
            )
          }
        },
        {
          title: '墙钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('墙钩', index)
            )
          }
        },
        {
          title: '窗幔',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('窗幔', index)
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('超高', index)
            )
          }
        },
        {
          title: '其他',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('其他', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ];
      columnsDetails = [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'room',
          width: 100,
        },
        {
          title: '长度（米）',
          align: 'center',
          dataIndex: 'width',
          width: 80,
        },
        {
          title: '品类',
          align: 'center',
          dataIndex: 'prop1',
          width: 80,
        },
        {
          title: '类型',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop3 !== '' && JSON.parse(record.prop3).name}</div>
            )
          }
        },
        {
          title: '层数',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop4 !== '' && JSON.parse(record.prop4).name}</div>
            )
          }
        },
        {
          title: '四角钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop5 !== '' && JSON.parse(record.prop5).name}</div>
            )
          }
        },
        {
          title: '墙钩',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop6 !== '' && JSON.parse(record.prop6).name}</div>
            )
          }
        },
        {
          title: '窗幔',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop7 !== '' && JSON.parse(record.prop7).name}</div>
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop8 !== '' && JSON.parse(record.prop8).name}</div>
            )
          }
        },
        {
          title: '其他',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop9 !== '' && JSON.parse(record.prop9).name}</div>
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop2}</div>
            )
          }
        },
        {
          title: '师傅费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop11}</div>
            )
          }
        },
        {
          title: '商户费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop10}</div>
            )
          }
        },
      ];
    } else if (type === '墙布墙纸') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
            )
          }
        },
        {
          title: '材质',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            let grid = this.state.datas.grid;
            let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '规格',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('规格', index)
            )
          }
        },
        {
          title: '施工方式',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('施工方式', index)
            )
          }
        },
        {
          title: '施工工艺',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('施工工艺', index)
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('拆旧', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onBlur={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ];
      columnsDetails = [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'room',
          width: 100,
        },
        {
          title: '品类',
          align: 'center',
          dataIndex: 'prop1',
          width: 80,
        },
        {
          title: '材质',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop3 !== '' && JSON.parse(record.prop3).name}</div>
            )
          }
        },
        {
          title: '规格',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop4 !== '' && JSON.parse(record.prop4).name}</div>
            )
          }
        },
        {
          title: '施工方式',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop5 !== '' && JSON.parse(record.prop5).name}</div>
            )
          }
        },
        {
          title: '施工工艺',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop6 !== '' && JSON.parse(record.prop6).name}</div>
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop7 !== '' && JSON.parse(record.prop7).name}</div>
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop2}</div>
            )
          }
        },
        {
          title: '师傅费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop9}</div>
            )
          }
        },
        {
          title: '商户费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop8}</div>
            )
          }
        },
      ]
    } else if (type === '晾衣架') {
      columns = [
        {
          title: '房间名称',
          align: 'center',
          width: 100,
          render: (text, record, index) => {
            return (
              <div className={styles['room_div_style']} onClick={this.getRoomModal.bind(this, index, record.room)}>{record.room}</div>
            )
          }
        },
        {
          title: '类型',
          align: 'center',
          width: 300,
          render: (text, record, index) => {
            if (JSON.stringify(this.state.datas) === '{}') {
              return;
            }
            if (!this.state.datas.type.includes('安装')) {
              return;
            }
            let grid = this.state.datas.grid;
            let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[index])[0].detail;
            return (
              <div className='row-sb'>
                <div className='col'>
                  {
                    grid.map((ditem, dindex) => {
                      return (
                        <div
                          className={`${styles['group-div']} ${this.state.groupType[index] === ditem.group && styles['group-div-color']}`}
                          key={dindex}
                          onClick={this.onClickGrid.bind(this, ditem.group, index)}
                        >
                          {ditem.group}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={styles['vertical-line']}></div>
                <Radio.Group value={this.state.detailType[index]} className='row-w'>
                  {
                    detail.map((ditem, dindex) => {
                      return (
                        <Radio value={ditem.name} key={dindex} onClick={this.onClickDetail.bind(this, ditem.name, ditem.id, index)}>{ditem.name}</Radio>
                      )
                    })
                  }
                </Radio.Group>
              </div>
            )
          }
        },
        {
          title: '驱动方式',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('驱动方式', index)
            )
          }
        },
        {
          title: '安装面',
          align: 'center',
          width: 150,
          render: (text, record, index) => {
            return (
              this.tableType('安装面', index)
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              this.tableType('超高', index)
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 200,
          render: (text, record, index) => {
            return (
              this.tableType('拆旧', index)
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <InputNumber min={1} defaultValue={record.prop2} onChange={this.getInputVaule.bind(this, 'prop2', index)} />
            )
          }
        },
        {
          title: '费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{this.getProp2Price(record.prop2, index)}</div>
            )
          }
        },
        {
          title: '操作',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div><DeleteOutlined className={styles['delete-style']} onClick={this.ondelData.bind(this, index)} /></div>
            )
          }
        },
      ];
      columnsDetails = [
        {
          title: '名称',
          align: 'center',
          dataIndex: 'room',
          width: 100,
        },
        {
          title: '品类',
          align: 'center',
          dataIndex: 'prop1',
          width: 80,
        },
        {
          title: '类型',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop3 !== '' && JSON.parse(record.prop3).name}</div>
            )
          }
        },
        {
          title: '驱动方式',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop4 !== '' && JSON.parse(record.prop4).name}</div>
            )
          }
        },
        {
          title: '安装面',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop5 !== '' && JSON.parse(record.prop5).name}</div>
            )
          }
        },
        {
          title: '超高',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop6 !== '' && JSON.parse(record.prop8).name}</div>
            )
          }
        },
        {
          title: '拆旧',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop7 !== '' && JSON.parse(record.prop7).name}</div>
            )
          }
        },
        {
          title: '数量',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop2}</div>
            )
          }
        },
        {
          title: '师傅费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop9}</div>
            )
          }
        },
        {
          title: '商户费用',
          align: 'center',
          width: 80,
          render: (text, record, index) => {
            return (
              <div>{record.prop8}</div>
            )
          }
        },
      ]
    }
    this.setState({
      columns: columns,
      columnsDetails: columnsDetails,
    })
  }

  getProp2Price = (prop2, index) => {
    let { orderPrice, postData, types, } = this.state;
    let price = 0;
    let multiple = this.getTypeProp2(postData[index].prop1, postData[index].width);
    let grapplePrice = this.getGrapplePrice(index, multiple);
    price = orderPrice[index] * Number(prop2) * multiple - grapplePrice.price_app * Number(prop2);
    return price;
  }
  getGrapplePrice = (index, multiple) => {
    let { optionsRadio, serviceItemG } = this.state;
    if (optionsRadio.length === 0) {
      return { price_app: 0, price_oms: 0 };
    }
    if (multiple === 1) {
      return { price_app: 0, price_oms: 0 };
    }
    if (!optionsRadio[index]['墙钩']) {
      return { price_app: 0, price_oms: 0 };
    }
    if (optionsRadio[index]['墙钩'].name === '无') {
      return { price_app: 0, price_oms: 0 };
    }
    let newOptionsRadio = optionsRadio[index];
    let newServiceItemG = serviceItemG[index] ? serviceItemG[index] : [];
    let data = newServiceItemG.filter(r => r.service === newOptionsRadio['墙钩'].id)[0];
    if (!data) { return { price_app: 0, price_oms: 0 }; }
    let price_app = data.price_app;
    let price_oms = data.price_oms;
    return {
      price_app: price_app * multiple - price_app,
      price_oms: price_oms * multiple - price_oms,
    }
  }
  getTypeProp2 = (type, width) => {
    let quantity = 0;
    let widths = Number(width);
    let prop2Multiple = 0;
    if (type === '布帘') {
      quantity = 3;
      if (widths > 1) {
        widths = widths - 1;
      }
    } else if (type === '成品帘') {
      quantity = 2.5;
    } else if (type === '电动成品帘') {
      quantity = 3;
    } else {
      quantity = 5;
    }
    prop2Multiple = Math.floor(widths / quantity) + 1;
    if (this.state.fixedPrice === '是') {
      return 1;
    }
    return prop2Multiple;
  }
  //不参与一口价
  getTypeProp = (type, width) => {
    let quantity = 0;
    let widths = Number(width);
    let prop2Multiple = 0;
    if (type === '布帘') {
      quantity = 3;
      if (widths > 1) {
        widths = widths - 1;
      }
    } else if (type === '成品帘') {
      quantity = 2.5;
    } else if (type === '电动成品帘') {
      quantity = 3;
    } else {
      quantity = 5;
    }
    prop2Multiple = Math.floor(widths / quantity) + 1;
    return prop2Multiple;
  }
  getTypePropOms = (type, width, category,) => {
    let prop2Multiple = 1;
    let widths = Number(width);
    if (type === '布帘') {
      if (category.includes('梦幻帘')) {
        prop2Multiple = 1;
      } else {
        if (widths > 4) {
          prop2Multiple = 2;
        }
      }
    }
    return prop2Multiple;
  }


  //获取表格类型
  getCaseType = (types, prop) => {
    let caseType = '';
    if (types === '窗帘') {
      switch (prop) {
        case 'prop4':
          caseType = '层数';
          break;
        case 'prop5':
          caseType = '四角钩';
          break;
        case 'prop6':
          caseType = '墙钩';
          break;
        case 'prop7':
          caseType = '窗幔';
          break;
        case 'prop8':
          caseType = '超高';
          break;
        case 'prop9':
          caseType = '其他';
          break;
        default:
          break;
      }
    } else if (types === '墙布墙纸') {
      switch (prop) {
        case 'prop4':
          caseType = '规格';
          break;
        case 'prop5':
          caseType = '施工方式';
          break;
        case 'prop6':
          caseType = '施工工艺';
          break;
        case 'prop7':
          caseType = '拆旧';
          break;
        default:
          break;
      }
    } else if (types === '晾衣架') {
      switch (prop) {
        case 'prop4':
          caseType = '驱动方式';
          break;
        case 'prop5':
          caseType = '安装面';
          break;
        case 'prop6':
          caseType = '超高';
          break;
        case 'prop7':
          caseType = '拆旧';
          break;
        default:
          break;
      }
    }
    return caseType;
  }
  //第一次获取品类数据
  getOptionsRadios = () => {
    let optionsRadio = [];
    this.state.postData.forEach((pitem, pindex) => {
      let detail = this.state.datas.grid.filter(r => r.group === this.state.groupType[pindex])[0].detail;
      let detailAdd = detail.filter(r => r.name === this.state.detailType[pindex])[0].add;
      let newOptionsRadio = {};
      detailAdd.forEach(ditem => {
        switch (ditem.group) {
          case this.getCaseType(this.state.data.category, 'prop4'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop4)
            break;
          case this.getCaseType(this.state.data.category, 'prop5'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop5)
            break;
          case this.getCaseType(this.state.data.category, 'prop6'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop6)
            break;
          case this.getCaseType(this.state.data.category, 'prop7'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop7)
            break;
          case this.getCaseType(this.state.data.category, 'prop8'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop8)
            break;
          case this.getCaseType(this.state.data.category, 'prop9'):
            newOptionsRadio[ditem.group] = JSON.parse(pitem.prop9)
            break;
          default:
            break;
        }
      });
      optionsRadio.splice(pindex, 1, newOptionsRadio)
    })
    this.setState({
      optionsRadio: optionsRadio,
    })
  }
  //获取金额
  getAllPrices = () => {
    const { orderPrice, data, postData, type, price_app, shopPrice, } = this.state;
    let orderPriceNUM = 0;
    if (postData.length === 0) {
      return 0;
    }
    orderPrice.forEach((item, index) => {
      if (type === '安装') {
        let multiple = this.getTypeProp2(postData[index].prop1, postData[index].width);
        let grapplePrice = this.getGrapplePrice(index, multiple);
        orderPriceNUM += item * Number(postData[index].prop2) * multiple - grapplePrice.price_app * Number(postData[index].prop2);
      } else {
        orderPriceNUM += item
      }
    });
    let price = orderPriceNUM > shopPrice ? orderPriceNUM : shopPrice;
    return price + price_app;
    // let price = orderPriceNUM + data.add_app + price_app;
    // return price > 79 ? price : 79
  }
  getAllPricesOms = () => {
    const { orderPrice_oms, postData, type, workerPrice, } = this.state;
    let orderPriceNUM = 0;
    if (postData.length === 0) {
      return 0;
    }
    orderPrice_oms.forEach((item, index) => {
      if (type === '安装') {
        let prop3 = postData[index].prop3 ? JSON.parse(postData[index].prop3).name : '';
        let prop9 = postData[index].prop9 ? JSON.parse(postData[index].prop9).name : '';
        let multiple = this.getTypePropOms(postData[index].prop1, postData[index].width, prop3);
        let grapplePrice = this.getGrapplePrice(index, multiple);
        orderPriceNUM += item * Number(postData[index].prop2) * multiple - grapplePrice.price_oms * Number(postData[index].prop2);
      } else {
        orderPriceNUM += item
      }
    });
    let price = orderPriceNUM > workerPrice ? orderPriceNUM : workerPrice;
    return price;
  }
  //保存
  onOk = async () => {
    let { postData, type, modalType, orderPrice, orderPrice_oms, tbRemark, customTime, customRemark,
      selectVideo, videoList, videoPlay } = this.state;
    let data = { ...this.state.data }
    if (modalType === '订单详情') {
      this.onCancel();
      return;
    }
    this.setState({
      editLoading: true,
    });
    // if (tbRemark) {
    //   data.shop_remark += `；唯一订单号：${tbRemark}；`;
    // }
    if (tbRemark) {
      data.name = data.name + 'DD' + tbRemark;
    }
    data.shop_remark = data.shop_remark + '✱' + customTime + '✱' + customRemark;
    data.serviceDatas = postData;
    data.pic_remark = JSON.stringify(data.pic_remark);
    data.vod_remark = [];
    if (selectVideo.length > 0) {
      selectVideo.map(r => {
        let single = {
          name: videoList[r].name,
          videoId: videoList[r].videoId,
        };
        data.vod_remark.push(single)
      })
    }
    if (videoPlay.length > 0) {
      let videos = videoPlay.map(item => {
        return {
          name: item.name,
          videoId: item.videoId,
          isVideo: true,
        };
      })
      data.vod_remark = data.vod_remark.concat(videos);
    }
    data.vod_remark = JSON.stringify(data.vod_remark);
    let price_app = 0;
    let price_oms = 0;
    if (type === '安装' && data.category !== '地板' && data.category !== '其他'
      && data.type !== '二次上门' && data.type !== '售后订单') {
      price_app = this.getAllPrices()
      price_oms = this.getAllPricesOms()
    } else {
      price_app = this.getPrices_app(data.price_app)
      price_oms = this.getPrices_oms(data.price_oms)
    }
    // console.log(data, this.getPrices_oms(data.price_oms))
    // return;
    data.price_app = price_app;
    data.price_oms = price_oms;
    if (!data.add_remark) {
      data.add_remark = ''
    }
    if (!data.add_worker_remark) {
      data.add_worker_remark = ''
    }
    let addition = [];
    data.serviceDatas.forEach((item, index) => {
      let prop3 = item.prop3 ? JSON.parse(item.prop3).name : '';
      let prop9 = item.prop9 ? JSON.parse(item.prop9).name : '';
      let multiple = this.getTypeProp2(item.prop1, item.width);
      let multiple2 = this.getTypePropOms(item.prop1, item.width, prop3);
      if (data.category === '窗帘') {
        item.prop10 = orderPrice[index] * Number(item.prop2) * multiple + '';
        item.prop11 = orderPrice_oms[index] * Number(item.prop2) * multiple2 + '';
      } else if (data.category === '墙布墙纸' || data.category === '晾衣架') {
        item.prop8 = orderPrice[index] * Number(item.prop2) * multiple + '';
        item.prop9 = orderPrice_oms[index] * Number(item.prop2) * multiple2 + '';
      }
      if (type === '安装') {
        if (item.prop1 === '电动布帘' || item.prop1 === '电动成品帘') {
          if (!addition.includes('电动轨道')) {
            addition.push('电动轨道');
          }
        }
        if (JSON.parse(item.prop3).name === '梦幻帘/垂直帘') {
          if (!addition.includes('梦幻帘')) {
            addition.push('梦幻帘');
          }
        }
        if (JSON.parse(item.prop3).name === '磁控百叶') {
          if (!addition.includes('磁控百叶')) {
            addition.push('磁控百叶');
          }
        }
        if (JSON.parse(item.prop3).name === '窗帘盒轨道') {
          if (!addition.includes('窗帘盒')) {
            addition.push('窗帘盒');
          }
        }
      }
    })
    if (data.price_app > 1000) {
      addition.push('工程单');
    }
    data.addition = addition.join(',');
    let serviceRoom = true;
    let serviceWidth = true;
    if (data.serviceDatas.length > 0) {
      data.serviceDatas.forEach(item => {
        if (item.room === 0) {
          serviceRoom = false;
        }
        if (item.width === 0 || item.width === '') {
          serviceWidth = false;
        }
      })
    }
    if (!serviceRoom) {
      this.setState({
        postSpin: false,
        editLoading: false,
      })
      message.error('请填写窗帘名称');
      return;
    }
    if (!serviceWidth) {
      this.setState({
        postSpin: false,
        editLoading: false,
      })
      message.error('请填写长度');
      return;
    }
    let res = await putOrder(data.id, data);
    if (res.status !== 204) {
      message.error(res.data);
      this.setState({
        editLoading: false,
      })
      return;
    }
    this.setState({
      editLoading: false,
    })
    this.onOkSuccess(data)
  }
  //调用父组件方法传值
  onOkSuccess = (data) => {
    let dataIndex = { ...this.state.dataIndex };
    dataIndex.address = data.address;
    dataIndex.city = data.city;
    dataIndex.address = data.address;
    dataIndex.district = data.district;
    dataIndex.name = data.name;
    dataIndex.phone = data.phone;
    dataIndex.price_app = data.price_app;
    dataIndex.add_app = data.add_app;
    dataIndex.add_oms = data.add_oms;
    dataIndex.price_oms = data.price_oms;
    // dataIndex.price_app = data.price_app + data.add_app;
    dataIndex.province = data.province;
    dataIndex.province = data.province;
    dataIndex.shop_remark = data.shop_remark;
    this.props.updateTable(dataIndex);
    this.onCancel();
  }
  //关闭modal
  onCancel = () => {
    let postData = [];
    postData = [{ ...serviceDatas }]
    this.setState({
      visible: false,
      postData: postData,
      orderPrice: [0],
      orderPrice_oms: [0],
      datas: {},
      data: {},
      optionsRadio: [],
      tbRemark: '',
      videoList: [],
      selectVideo: [],
      videoPlay: [],
      recordData: {
        create_time: '',
        contact_time: '',
        app_gap: '',
        changeVisit: '',
        appoint_time: '',
        sign_time: '',
        service_time: '',
        installRatio: '',
      },
      modalsItem: '订单详情',
    })
  }
}

export default IndexModal;