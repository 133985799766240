import React, { Component, } from 'react';
import ReactDOM from 'react-dom';
import styles from './BasicLayout.module.css'
import mmLogo from '../resource/png/mmLogo.png';
import { MenuConfig, menuGlobal } from '../config/router';
import { Link } from 'dva/router';
// import OrderRenter from '../routes/orderRenter/orderRenter';
import history from '../config/history';
import { assembly, getChildren } from './children';
import { CloseCircleFilled, LogoutOutlined, DownOutlined, UpOutlined, SyncOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';
import {
  getDepartment_Data, setNavigation_Type, setRequest, getNavigation_Type, removeSession,
  removeUser, getSession
} from '../config/storage';
import { KeepaliveRouterSwitch, KeepaliveRoute, addKeeperListener } from 'react-keepalive-router';
import Panels from '../routes/panels/panels';
import orderRenter from '../models/orderRenter';
import { connect } from 'dva';

const AppContext = React.createContext();

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      path: [],
      close: false,
      tagName: [],
      tagType: '',
      name: '',
      subMenu: false,
      subMenuType: '',
      role: '0',
    };
  }
  componentDidMount() {
    let departmentData = getDepartment_Data();
    if (!departmentData || !getSession()) {
      this.outLogin();
    } else {
      this.setState({
        tagName: ['全部订单汇总'],
        tagType: '全部订单汇总',
        name: departmentData.name,
        role: departmentData.roles[0],
      });
    }
  }
  render() {
    const { path, close, tagName, tagType, name, subMenu, subMenuType, role } = this.state;
    const menu = () => {
      return (
        <Menu>
          <Menu.Item key={'全部'} onClick={this.onDel.bind(this, '全部')}>
            关闭全部选项
          </Menu.Item>
          <Menu.Item key={'其他'} onClick={this.onDel.bind(this, '其他')}>
            关闭其他选项
          </Menu.Item>
        </Menu>
      )
    }
    return (
      <AppContext.Provider value={{
        path: path
        // setPath: setPath
      }}>
        <div style={{ minWidth: '1920px' }}>
          <div className={`${styles['sidebar']} ${close && styles['close']}`}>
            <div className={`${styles['logo-details']} col`}>
              <i>
                <img alt="logo" src={mmLogo} />
              </i>
              <span className={styles['logo_name']} >墨墨管家</span>
            </div>
            <ul className={styles['nav-links']}>
              {this.createMenu(assembly)}
            </ul>
          </div>
          <section className={styles['home-section']}>
            <div className={styles['home-top']}>
              <div className={styles['home-content']}>
                <div className={`${close ? styles['rotateChange1'] : styles['rotateChange']}`} onClick={this.toClose}>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span className={styles['text']}>欢迎登录墨墨管家测量安装服务系统</span>
              </div>
              <div className={`${styles['navigation-tag']} row-sb`}>
                <div className={`${styles['tag-div']} row-l`}>
                  {
                    tagName.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={`${styles['tag-right']} row ${tagType === item && styles['tag-right-color']}`}
                          onClick={this.onClickTagName.bind(this, item)}
                        >
                          <span style={{ marginRight: 5 }}>{item}</span>
                          <div onClick={this.onDelTagName.bind(this, item)}>
                            <CloseCircleFilled className={styles['div-icon-color']} />
                          </div>
                          {/* <div >
                              <SyncOutlined className={styles['syncOutlined_styles']} />
                            </div> */}
                        </div>
                      )
                    })
                  }
                </div>
                <div className={`${styles['tag-operation']} row-l`}>
                  <div className={`${styles['tag-right']} padding-lr20`}>
                    <Dropdown overlay={menu}>
                      <div>
                        关闭操作
                        <DownOutlined />
                      </div>
                    </Dropdown>
                  </div>
                  <div className={styles['tag-right']}>{name},您好!</div>
                  <div className={`${styles['tag-right']} row`} onClick={this.outLogin}>
                    <LogoutOutlined style={{ fontSize: 14, marginRight: 5 }} />退出登录
                  </div>
                </div>
              </div>
            </div>
            <div className={`${tagType === '服务区域' ? styles['home-children1'] : styles['home-children']}`}>
              {getChildren(tagType, this.onSetTagName)}
              {/* {children} */}
            </div>
            {/* <div className={styles['home-bg']}>
                <Panels />
              </div> */}
          </section>
        </div>
      </AppContext.Provider>
    );
  }
  //左侧导航
  createMenu = (data) => {
    const { role, tagType, subMenu, subMenuType } = this.state;
    let newData = data.map((item, index) => {
      let resoult = this.getRoles(role, item.name);
      if (resoult) {
        if (item.childRoutes && item.childRoutes.length > 0) {
          return (
            <li key={item.path} onClick={this.subMenuTypes.bind(this, item.name)}>
              <div className={`${styles['iocn-link']} col vertical-lt`}>
                <div className='col'>
                  <div>
                    <i>
                      {item.icon}
                    </i>
                    <span className={styles['link_name']}>{item.name}</span>
                    {(subMenu && subMenuType === item.name) ?
                      <UpOutlined className={`${styles['bxs-chevron-down']}`} />
                      : <DownOutlined className={`${styles['bxs-chevron-down']}`} />}
                  </div>
                </div>
                {(subMenu && subMenuType === item.name) && <div className={'col w100'}>
                  {
                    item.childRoutes.map((cItem, cIndex) => {
                      if (this.getRoles2(role, cItem.name)) {
                        return;
                      }
                      return (
                        <div key={cIndex} onClick={this.onSetNameClick.bind(this, cItem.name)}
                          className={`${styles['link_name1']} ${tagType === cItem.name && styles['left-li-style']}`}
                        >{cItem.name}</div>
                      )
                    })
                  }
                </div>}
              </div>
            </li>
          )
        } else {
          return (
            <li key={item.path} className={`${tagType === item.name && styles['left-li-style']}`}>
              <div className={`${styles['iocn-link']}`} onClick={this.onSetTagName.bind(this, item.name)}>
                <div className='col'>
                  <div>
                    <i>
                      {item.icon}
                    </i>
                    <span className={styles['link_name']}>{item.name}</span>
                    {/* <i className={`${styles['arrow']} ${styles['bx']} ${styles['bxs-chevron-down']}`}></i> */}
                    {/* {item.childRoutes && <DownOutlined className={`${styles['bxs-chevron-down']}`} />} */}
                  </div>
                </div>
              </div>
            </li>
          )
        }
      }
    });
    return newData;
  };
  toClose = () => {
    this.setState({
      close: !this.state.close
    });
  }
  //1商户客服，5区域客服，6数据客服，7商家客服主管，8区域客服主管，9运营总监，11财务总监
  // || path === '客服维护'
  getRoles = (role, path) => {
    if (path === '服务区域') {
      return true;
    }
    if (path === '工作台') {
      return true;
    }

    if (role === 1) {
      //1商户客服
      if (path === '订单中心' || path === '代客户下单' || path === '售后中心' || path === '测量数据'
        || path === '商户维护') {
        return true;
      }
    } else if (role === 5) {
      // 5区域客服
      if (path === '订单中心' || path === '售后中心' || path === '师傅维护' || path === '订单财务') {
        return true;
      }
    } else if (role === 6) {
      // 6数据客服
      if (path === '测量数据' || path === '售后中心') {
        return true;
      }
    } else if (role === 7) {
      // 7商家客服主管
      if (path === '订单中心' || path === '代客户下单' || path === '售后中心' || path === '测量数据'
        || path === '商户维护' || path === '分析中心') {
        return true;
      }
    } else if (role === 8) {
      // 8区域客服主管
      if (path === '订单中心' || path === '售后中心' || path === '师傅维护' || path === '分析中心') {
        return true;
      }
    } else if (role === 9 || role === 11) {
      // 9运营总监 11财务总监
      return true;
    } else if (role === 12) {
      //区域优化
      if (path === '订单中心' || path === '售后中心' || path === '师傅维护' || path === '分析中心') {
        return true;
      }
    }
  }
  //2级 没有的权限
  // 1商户客服，5区域客服，6数据客服，7商家客服主管，8区域客服主管，9运营总监，11财务总监 12区域优化
  // ['平台订单分析','各区数据对比','单日订单结算分析','商户订单分析','师傅订单分析','财务分析','售后分析','业务开发分析','客服产能分析']
  getRoles2 = (role, path) => {
    if (role === 5) {
      if (path === '订单审核' || path === '待收款订单' || path === '师傅提现') {
        return true;
      }
    } else if (role === 7) {
      if (path === '单日订单结算分析' || path === '师傅订单分析' || path === '财务分析' || path === '客服产能分析') {
        return true;
      }
    } else if (role === 8) {
      if (path === '商户订单分析' || path === '财务分析' || path === '业务开发分析') {
        return true;
      }
    } else if (role === 12) {
      if (['平台订单分析', '单日订单结算分析', '商户订单分析', '财务分析', '业务开发分析', '客服产能分析'].includes(path)) {
        return true;
      }
    }
    return false;
  }
  subMenuTypes = (e) => {
    this.setState({
      subMenu: !this.state.subMenu,
      subMenuType: e,
    })
  }

  onSetNameClick = (name, e) => {
    this.onSetTagName(name);
    e.stopPropagation();
  }
  //退出登录
  outLogin = () => {
    removeSession();
    removeUser();
    history.push('/login');
    window.location.replace('');
    window.localStorage.clear();
  }
  //删除标签
  onDelTagName = (item, e) => {
    e.stopPropagation();
    const { tagName, tagType, } = this.state;

    let newTagName = tagName.filter(r => r !== item);
    if (item === tagType) {
      setRequest(false)
    }
    this.setState({
      tagName: newTagName,
      tagType: newTagName[0],
    });
    setNavigation_Type(newTagName);
    if (item === '全部订单汇总') {
      window.setTimeout(() => {
        this.props.dispatch({
          type: 'orderRenter/delTerms',
          payload: '',
        });
      }, 500)
    }
  }
  //切换标签
  onClickTagName = (item) => {
    setRequest(false)
    this.setState({
      tagType: item
    });
  }
  //新增标签
  onSetTagName = (e) => {
    const { tagName } = this.state;
    let type = tagName.includes(e);
    if (!type) {
      setNavigation_Type([...tagName, e])
      setRequest(true)
      this.setState({
        tagName: [...tagName, e]
      })
    } else {
      setRequest(false)
    }
    this.setState({
      tagType: e
    });
  }
  //关闭操作
  onDel = (item) => {
    const { tagType } = this.state;
    if (item === '全部') {
      setRequest(true);
      setNavigation_Type([]);
      this.setState({
        tagName: [],
        tagType: '',
      })
      window.setTimeout(() => {
        this.props.dispatch({
          type: 'orderRenter/delTerms',
          payload: '',
        });
      }, 500)
      this.props.dispatch({
        type: 'orderRenter/panelToAllOrder',
        payload: '',
      });
    } else if (item === '其他') {
      setRequest(false);
      setNavigation_Type([tagType]);
      this.setState({
        tagName: [tagType]
      })
      if (tagType !== '全部订单汇总') {
        window.setTimeout(() => {
          this.props.dispatch({
            type: 'orderRenter/delTerms',
            payload: '',
          });
        }, 500)
        this.props.dispatch({
          type: 'orderRenter/panelToAllOrder',
          payload: '',
        });
      }
    }
  }
}
// export default Index;
export default connect(({ orderRenter }) => (orderRenter))(Index);