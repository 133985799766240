import React, { Component } from 'react';
import {
  Table, Modal, Input, DatePicker, message, Upload, TimePicker, Popover, Button, Radio, Image,
  InputNumber, Popconfirm, Spin
} from 'antd';
import styles from './index.module.css';
import UrgeOrder from '../../../resource/png/urge_order.png';
import UrgeOrder1 from '../../../resource/png/urge_order1.png';
import IndexModal from './indexModal';
import OrderTipModal from './orderTipModal';
import MapModal from '../allOrder/masterMap';
import ComplaintModal from './complaintModal';
import MeasureModal from '../../measureData/indexModal';
import MasterModal from '../../masterManagement/modal';
import ShopModal from '../../shopManagement/modal';
import AppointModal from './appointModal';
import ReminderModal from './reminderModal';
import SignModal from './signModal';
import MsgModal from './msgModal';
import BonusModal from './bonusModal';
import AfterSaleModal from './afterSaleModal';
import ComplainModal from './complainModal.jsx';
import FeedModal from './feedModal.jsx';
import {
  postFollow, putAllocate, putCancelorder, putPushorder, putUrgecontact, delLabel, getExcel,
  getMasterManager, putTwice, getInstallPic, putInstallPic, getCheckServicer, putAppointTime,
  getCustomManager, getShopInfo, putCheckbonus, getOrder, puOrderData, sendMessage
} from '../../../services/orderCore';
import {
  getVideoInfo, getVideoPlay, getVideoSub,
} from '../../../services/newOder';
import { PlusOutlined, DeleteOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getOssBaseAuth, getDepartment_Data } from '../../../config/storage';
import convert from '../../../utils/convert';
import { ossUpload, } from '../../../utils/ossUpload.js';
import WorkerModal from '../allOrder/workerModal';
import Link from '../../../resource/png/masterManagement-link.png';
import Tong from '../../../resource/png/tong.png';
import locale from 'antd/es/date-picker/locale/zh_CN';
moment.locale('zh-cn');

const dateFormat = 'YYYY-MM-DD';
const weekLater = moment().add(7, 'day').format(dateFormat);

const quickRemark = ['分机号过期', '电话未接', '未装修好', '卫生未完成', '未到货', '到货等通知', '拒接电话', '时间暂不确定'];
// const quickRemark = ['客户电话打不通', '客户不着急等客户通知', '货不齐等客户通知', '不满足测量条件等客户通知'];
const getWeek = (e) => {
  let day = new Date(e).getDay();
  let date = '';
  switch (day) {
    case 0: date = '周日'; break;
    case 1: date = '周一'; break;
    case 2: date = '周二'; break;
    case 3: date = '周三'; break;
    case 4: date = '周四'; break;
    case 5: date = '周五'; break;
    case 6: date = '周六'; break;
  }
  return date;
};
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: 0,
      operationVisible: false,
      operationID: 0,
      operationType: '',
      operationSuccess: true,
      date: new Date().toLocaleDateString().split('/').join('-'),
      workerData: {
        id: 0,
        name: ''
      },
      textAreaValue: '',
      content: '',
      signPic: [],
      selectedRowKeys: [],
      selectedRows: [],
      allRecordVisible: false,
      confirmLoading: false,
      allRecordData: [],
      operationIndex: 0,
      appointDateTime: moment().format('HH') + ':00',
      twiceVisible: false,
      twiceLoading: false,
      twiceId: 0,
      twiceType: '',
      twiceRemark: '',
      twicePic: [],


      currentOrder: 0,
      installModal: {
        visible: false,
        installPic: [],
        praisePic: [],
        praiseRemark: '',
        praiseStatus: '',
        praiseMode: '',
        price_s: 0,
        price_w: 0,
        vod: [],
        videos: [],
      },
      urgeLoading: false,
    }

    this.allRecorColumns = [
      {
        title: '操作类型',
        dataIndex: 'type',
        align: 'center',
        width: 100,
      },
      {
        title: '客服',
        dataIndex: 'name',
        align: 'center',
        width: 100,
      },
      {
        title: '操作时间',
        dataIndex: 'submit_time',
        align: 'center',
        width: 100,
      },
      {
        title: '操作内容',
        dataIndex: 'content',
        align: 'center',
        width: 100,
      },
    ]
    this.indexModal = React.createRef();
    this.orderTipModal = React.createRef();
    this.workerModal = React.createRef();
    this.mapModal = React.createRef();
    this.complaintModal = React.createRef();
    this.measureModal = React.createRef();
    this.masterModal = React.createRef();
    this.shopModal = React.createRef();
    this.appointModal = React.createRef();
    this.reminderModal = React.createRef();
    this.signModal = React.createRef();
    this.msgModal = React.createRef();
    this.bonusModal = React.createRef();
    this.afterSaleModal = React.createRef();
    this.complainModal = React.createRef();
    this.feedModal = React.createRef();
    this.baseUrl = getDepartment_Data().service;
  }
  componentDidMount() {
    let res = getDepartment_Data();
    this.setState({
      role: res.roles[0],
    })
  }
  render() {
    const { operationVisible, operationType, operationSuccess, date, workerData, allRecordVisible, allRecordData,
      confirmLoading, appointDateTime, content,
      twiceVisible, twiceLoading, twiceType, twiceRemark, twicePic,
      textAreaValue, signPic, installModal, role, urgeLoading,
    } = this.state;
    const rowSelection = {
      // columnTitle: '选择',
      selectedRowKeys: this.props.selectedRowKeys,
      type: 'checkbox',
      columnWidth: 40,
      onChange: this.onSelectChange,
    };
    const columns = [
      {
        title: '订单信息',
        align: 'center',
        width: 100,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col h100`}>
              <div className={`${styles['columns-span']}`}>{record.id}</div>
              <div className={`${styles['left-top-type']} ${record.service === '安装' ? 'background1' : 'background2'}`}>
                <div className={`${styles['left-top-type-span']}`}>{record.service}</div>
              </div>
              <div className={`${styles['columns-span']} margin-t5`}>
                {record.category + '-'}
                <span className='fw600 color4'>{record.type}</span>
              </div>
              <div className={`${styles['columns3-type-span']} margin-t5 color2`}>
                【 {record.state} 】
              </div>
              {record.service === '测量' &&
                <div className={`${styles['columns3-type-span']} margin-t5 color2`}>
                  【 {record.collate_state} 】
                </div>}
              {record.order_way !== '客户下单' &&
                <span className={`${styles['abnormal_div']} margin-t5`}>
                  {record.order_way}
                </span>
              }
              {
                record.type === '正常订单' && record.service === '测量' &&
                <div className={`${styles['abnormal_div']} margin-t5`}>{record.order > 0 ? '已转化:' : '未转化'}{record.order > 0 ? record.order : ''}</div>
              }
              {
                record.type === '正常订单' && record.service === '安装' &&
                <div className={`${styles['abnormal_div']} margin-t5`}>{record.order > 0 ? '已转化:' : '非转化'}{record.order > 0 ? record.order : ''}</div>
              }
            </div>
          )
        }
      },
      {
        title: '客户信息',
        align: 'center',
        width: 100,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} tc padding10 col`}>
              <div className={`${styles['remack-overflow-height']} ${styles['remack-overflow']}`}>
                <div className={`${styles['columns-span']} col`}>
                  <div className='row-l'>
                    <img src={Link} onClick={this.onCopyToClip.bind(this, record)} className='cursor-pointer' />
                    {record.name.split('DD')[0]}
                    {/* {record.name} */}
                  </div>
                  <div>{record.phone}</div>
                </div>
                <div className={`${styles['columns-span']} margin-t5 max200`}>
                  {record.province + record.city + record.district + record.address}
                </div>
              </div>
              {record.urge_contact === '是' &&
                <div className={styles['urgeOrder-type']}>
                  <div className={styles['left-top-type-span']}>催单</div>
                </div>}
              {record.urge_order === '是' &&
                <div className={styles['urgeOrder-type-right']}>
                  <div className={styles['right-top-type-span']}>加急</div>
                </div>}
              {
                this.isReminder(record.serviceFollows).length > 0 &&
                <div className='row'>
                  <div onClick={this.reminderShow.bind(this, this.isReminder(record.serviceFollows))}
                    className={`${styles['columns2-reminder-button']} margin-t10 `}
                  >
                    查看催单记录
                  </div>
                </div>
              }
              {/* <div className='row margin-r5'><div className={styles['columns2-copy-button']} onClick={this.onCopyToClip.bind(this, record)}>复制客户信息</div></div> */}
              {
                record.service === '安装' && record.state === '待审核' ||
                  record.service === '测量' && record.state === '待审核' ||
                  record.service === '安装' && record.state === '已终止' ||
                  record.service === '测量' && record.state === '已终止' ||
                  record.service === '安装' && record.state === '已完成' ||
                  record.service === '测量' && record.state === '已完成' ? <div></div> :
                  <div className='row'>
                    {
                      record.urge_contact === '是' ? (
                        <div></div>
                      ) : (
                        <div className='row'>
                          <Spin spinning={urgeLoading}>
                            <div className={styles['columns2-copy-button']} onClick={this.onReminder.bind(this, record.id)}>发起催单</div>
                          </Spin>
                        </div>
                      )
                    }
                  </div>}
              {
                record.mutil_order === '是' &&
                <Image src={Tong} className={styles['tong']} preview={false} />
              }

            </div>
          )
        }
      },
      {
        title: '下单信息',
        align: 'center',
        width: 100,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col tc`}>
              <div className='row margin-t5'>
                <Popover
                  content={
                    <div className='col-t vertical-lt'>
                      <div>负责人：{record.shopManager}</div>
                      <div>费用备注：{record.priceRemark}</div>
                      {
                        record.category === '窗帘' && record.service === '测量' &&
                        <div>整理人：{record.collector}</div>
                      }
                    </div>
                  }>

                  <div className={`color3 underline cursor-pointer`}
                    onMouseOver={this.onShopOver.bind(this, record, index)}
                    onClick={this.onEditShop.bind(this, record)}
                  >
                    {record.shopName}
                  </div>
                </Popover>
              </div>
              <div className={`${record.shopRank ? styles['grade_style2'] : ''}`}>{record.shopRank}</div>
              <div className={`${styles['columns-span']} margin-t5`}>{this.removeT(record.create_time)}</div>
              <div className={`${styles['columns-span']} margin-t5`}>下单人: {record.operater}</div>
              <div className='color2'>{record.addition ? record.addition : ''}</div>
            </div>
          )
        }
      },
      {
        title: '商家备注',
        align: 'center',
        width: 100,
        className: 'padding0 max170',
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col`}>
              <div className={`${styles['columns-span']} ${styles['remack-overflow']}`}>{record.shop_remark}</div>
              {
                record.name.split('DD')[1] &&
                <div className={styles['columns-span']}>唯一订单号：{record.name.split('DD')[1]}</div>
              }
              <img src={Link} onClick={this.onCopyToClip3.bind(this, record.shop_remark, '商家备注')} />
            </div>
          )
        }
      },
      {
        title: '官方客服备注',
        align: 'center',
        width: 100,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col`}>
              <span className={`${styles['columns-span']} ${styles['remack-overflow']}`}>{record.service_shop_remark}</span>
              <img src={Link} onClick={this.onCopyToClip3.bind(this, record.service_shop_remark, '官方客服备注')} />
            </div>
          )
        }
      },
      {
        title: '师傅信息',
        align: 'center',
        width: 120,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']} col`}>
              <div className={`${styles['left-top-type']} ${record.bonus_order === '是' ? 'background3' : ''}`}>
                <div className={`${styles['left-top-type-span']}`}>{'好评'}</div>
              </div>
              {record.workerName && <div className='row-l'>
                <img src={Link} onClick={this.onCopyToClip2.bind(this, record)} />
                {/* <Popover content={'负责人：' + this.getRelativeManager(record.workerId)}> */}
                <Popover
                  content={() => {
                    return <div className='col vertical-lt wm600'>
                      <div>负责人：{record.mname ? record.mname.name : ''}</div>
                      <div>区域合作计划：{record.mname ? record.mname.remark : ''}</div>
                    </div>
                  }}
                >
                  <div
                    onClick={this.onShowComplaints.bind(this, record)}
                    className={`${styles['columns-span']} cursor-pointer underline color3`}
                    onMouseOver={this.onMasterOver.bind(this, record, index)}
                  >
                    {record.workerName}
                  </div>
                </Popover>
                <div className={`${styles['grade_style']} margin-l5`}>{record.workerRank}</div>
              </div>}
              {
                record.workerPhone && <div className='row-l'>
                  <div className={`${styles['columns-span']} `}>{this.getWorkerPhone(record.workerPhone, record.workerDisplay)}</div>
                  <div className={styles['icon_style']} onClick={this.setWorkerDisplay.bind(this, index)}>{record.workerDisplay ? <EyeOutlined /> : <EyeInvisibleOutlined />}</div>
                </div>
              }
              {
                record.labels && record.labels.length > 0 ?
                  <div className='color2'>{JSON.parse(record.labels).join()}</div> : ''
              }
              {
                [5, 8, 9, 11, 12].includes(role) && record.workerName ?
                  < div className='cursor-pointer underline color3' onClick={this.onEditMaster.bind(this, record)} > 编辑师傅</div >
                  :
                  < div className={styles['columns1-date-span']} >{record.workerName ? '(联系手机)' : ''}</div >
              }
              {
                record.bonus_order === '是' &&
                <div className='margin-t10 color2'>{record.bonus_type === '固定金额' ? '【平台审核】' : '【商家审核】'}</div>
              }
              {/* {record.bonus_order === '是' && record.state === '已完成' && <img src={UrgeOrder} className={`${styles['img']}`} />} */}
            </div >
          )
        }
      },
      {
        title: '预约时间',
        align: 'center',
        width: 110,
        className: 'padding0',
        render: (text, record, index) => {
          let follows = [];
          let local = -1;
          record.serviceFollows.map((fitem, findex) => {
            if (fitem.content.includes('重新分配')) {
              local = findex
            }
          })
          follows = record.serviceFollows.slice(local + 1,);
          // let appointList = record.serviceFollows.filter(item => item.type === '预约')
          let appointList = follows.filter(item => item.type === '预约')
          let result = '--';
          if (appointList.length === 1) {
            result = appointList[0];
          } else if (appointList.length > 1) {
            appointList.sort((a, b) => {
              return b.id - a.id > 0;
            });
            result = appointList[appointList.length - 1]
          }
          let missTime = 0;
          if (['已签到', '待收款', '已完成'].includes(record.state)) {
            missTime = (moment(record.sign_time).unix() - moment(record.appoint_time).unix()) / 60 / 60
          }
          let efficiency = '';
          if (record.state === '已完成') {
            let hours = (new Date(record.compete_time).getTime() - new Date(record.create_time).getTime()) / 1000 / 3600;
            if (hours < 12) {
              efficiency = '12h';
            } else if (hours < 24) {
              efficiency = '24h';
            } else if (hours < 36) {
              efficiency = '36h';
            }
          }
          return (
            <div className={`${styles['columns-border']} col`}>
              {
                (Boolean(record.appoint_time) && record.appoint_time !== '0001-01-01T00:00:00'
                  && result && result.content && !result.content.includes('预约失败')) ?
                  <div className={styles['columns-span']}>
                    {moment(record.appoint_time).subtract(30, 'm').format('YYYY/MM/DD-HH:mm') + '~' + moment(record.appoint_time).add(30, 'm').format('HH:mm')}
                    {getWeek(record.appoint_time)}
                  </div>
                  :
                  <div className='col'>
                    <div className={`${styles['columns-span']} ${result === '--' ? '' : 'color2'}`}>{result.content}</div>
                    <div className={`${styles['columns-span']} color2`}>
                      {result === '--' ? '' : moment(result.submit_time).format('YYYY/MM/DD-HH:mm')}
                    </div>
                  </div>
              }
              <span className={styles['columns1-date-span']}>(预约时间)</span>
              {
                appointList.length > 0 &&
                <span onClick={this.appointRecord.bind(this, appointList)} className={'fz14 color3 cursor-pointer underline'}>预约记录</span>
              }
              {
                record.state === '预约失败' && record.appoint_time !== '0001-01-01T00:00:00' &&
                <div className='color2'>虚拟:{moment(record.appoint_time).format('YYYY/MM/DD-HH:mm')}</div>
              }
              {
                ['已签到', '待收款', '已完成'].includes(record.state) &&
                <div className='col'>
                  <div className={styles['columns-span']}>签到预约时差：{missTime.toFixed(1)}h</div>
                  <div onClick={this.showSign.bind(this, record)} className={'fz14 color3 cursor-pointer underline'}>签到信息</div>
                </div>
              }
              {
                record.state === '已完成' && efficiency !== '' &&
                <div className={`${styles['left-top-type']} background1`}>
                  <div className={`${styles['left-top-type-span']}`}>{efficiency}</div>
                </div>
              }
            </div>
          )
        }
      },
      {
        title: '商户费用',
        align: 'center',
        width: 60,
        className: 'padding0',
        render: (text, record, index) => {
          let transFee = record.serviceItems.filter(r => r.name === '安装转化费');
          return (
            <div className={`${styles['columns-border']} col`} >
              <span>{record.price_app + record.add_app}</span>
              {
                transFee[0] &&
                <span className={`${styles['abnormal_div']} margin-t5`}>
                  安装转化单
                </span>
              }
            </div>
          )
        }
      },
      {
        title: '师傅费用',
        align: 'center',
        width: 60,
        className: 'padding0',
        render: (text, record, index) => {
          let during_time = moment(record.contact_time).diff(moment(record.create_time)) / 1000 / 60 / 60;
          let threeh = true;
          record.serviceFollows.map((fitem, findex) => {
            if (fitem.content.includes('预约失败')) {
              threeh = false;
            }
          })
          let errorItem = [];
          if (record.service === '安装') {
            errorItem = record.serviceItems.filter(r => r.name.includes('测量'));
          }
          return (
            <div className={`${styles['columns-border']} col`} >
              <span>{record.workerId === 0 ? '' : record.price_oms + record.add_oms}</span>
              {
                errorItem.length > 0 &&
                <span className='color2 fz18 bold'>异常</span>
              }
              {
                during_time < 3 && during_time > 0 && threeh ?
                  <div className={`${styles['right-top-type']}`}><div className={styles['right-top-type-span']}>保</div></div>
                  : ''
              }

            </div>
          )
        }
      },
      {
        title: '订单操作',
        align: 'center',
        width: 140,
        className: 'padding0',
        render: (text, record, index) => {
          return (
            <div className={`${styles['columns-border']}`}>
              {
                record.state !== '已终止' &&
                <div className='row-w' >
                  <div className={`${styles['img-size']} ${styles['FollowUp-color']} row cursor-pointer`}
                    onClick={this.addOrderTip.bind(this, record)}
                  >
                    <div className={`${styles['FollowUp-style']}`}></div>
                    <div className='margin-l5 fz15'>跟进</div>
                  </div>
                  {
                    record.state !== '待审核' && record.state !== '已完成' &&
                    <div className={`${styles['img-size']} ${styles['Return-color']} row cursor-pointer`}
                      onClick={this.allotMaster.bind(this, record, '订单分配', index)}>
                      <div className={`${styles['Return-style']}`}></div>
                      <div className='margin-l5 fz15'>分配</div>
                    </div>
                  }
                  {
                    record.state !== '已完成' &&
                    <div className={`${styles['img-size']} ${styles['Advance-color']} row cursor-pointer`}
                      onClick={this.onOperationClick.bind(this, record, '推进', index)}>
                      <div className={`${styles['Advance-style']}`}></div>
                      <div className='margin-l5 fz15'>推进</div>
                    </div>
                  }
                  {
                    record.state !== '已完成' && record.state !== '待收款' && [1, 7, 8, 9, 11].includes(role) &&
                    <div className={`${styles['img-size']} ${styles['Prohibit-color']} row cursor-pointer`}
                      onClick={this.onOperationClick.bind(this, record, '终止', index)}>
                      <div className={`${styles['Prohibit-style']}`}></div>
                      <div className='margin-l5 fz15'>终止</div>
                    </div>
                  }
                </div>
              }
              {
                record.state !== '已终止' &&
                <div className={styles['splitLine']}></div>
              }
              <div className='row-w paddind10 margin-t5'>
                <div className={styles['style-button1']} onClick={this.onOpenModal.bind(this, record, '订单详情')}>
                  查看订单详情
                </div>
                {
                  !['已完成', '已终止'].includes(record.state) &&
                  <div className={styles['style-button2']} onClick={this.onOpenModal.bind(this, record, '修改订单')}>
                    修改订单
                  </div>
                }
                {record.state === '待上门' &&
                  <div className={styles['style-button2']} onClick={this.onOperationClick.bind(this, record, '预约', index)}>
                    修改预约时间
                  </div>
                }
                {record.service === '测量' && record.category === '窗帘' && ['待收款', '待审核', '已完成'].includes(record.state) &&
                  <div className={styles['style-button2']} onClick={this.uploadMeasure.bind(this, record)}>
                    上传测量数据
                  </div>
                }
                {
                  record.service === '安装' && (record.state === '待收款' || record.state === '已完成') &&
                  <div className={styles['style-button2']} onClick={this.onInstallShow.bind(this, record)}>
                    安装效果审核
                  </div>
                }
                {record.bonus_order === '是' && record.state === '已完成' && ['无', '已拒绝'].includes(record.bonus_state) &&
                  <div className={styles['style-button2']} onClick={this.onBonusShow.bind(this, record)}>
                    申请好评
                  </div>
                }
                {record.service === '测量' && ['已签到', '待收款', '待审核', '已完成'].includes(record.state) &&
                  <div className={styles['style-button1']} onClick={this.onExcelData.bind(this, record.id, '测量数据详情')}>
                    查看测量数据
                  </div>
                }
                {record.service === '测量' && ['已签到', '待收款', '待审核', '已完成'].includes(record.state) &&
                  <div className={styles['style-button2']} onClick={this.onExcelData.bind(this, record.id, '测量数据下载')}>
                    下载测量数据
                  </div>
                }
                {
                  !['待开发', '待分配', '待预约', '预约失败', '已终止'].includes(record.state) &&
                  <div className={styles['style-button2']} onClick={this.onTwiceShow.bind(this, record.id, '二次上门', record)}>
                    二次上门
                  </div>
                }
                {['待上门', '已签到', '待收款', '待审核', '已完成'].includes(record.state) && ['正常订单', '二次上门'].includes(record.type) &&
                  <div className={styles['style-button4']} onClick={this.onTwiceShow.bind(this, record.id, '售后订单', record)}>
                    售后订单
                  </div>
                }
                {['待上门', '已签到', '待收款', '待审核', '已完成'].includes(record.state) && ['正常订单', '二次上门'].includes(record.type) &&
                  <div className={styles['style-button4']} onClick={this.onAfterSale.bind(this, record)}>
                    申请理赔
                  </div>
                }
                {['待分配', '待接单', '待预约', '预约失败', '待上门'].includes(record.state) &&
                  <div className={styles['style-button1']} onClick={this.chargeToDevelop.bind(this, record)}>
                    添加到待开发
                  </div>
                }
                {['待预约', '预约失败', '待上门', '已签到', '待收款', '待审核', '已完成'].includes(record.state) &&
                  <div className={styles['style-button4']} onClick={this.toComplain.bind(this, record)}>
                    投诉
                  </div>
                }
                {['待预约', '预约失败', '待上门', '已签到', '待收款', '待审核', '已完成'].includes(record.state) &&
                  <div className={styles['style-button4']} onClick={this.toFeed.bind(this, record)}>
                    反馈
                  </div>
                }
                {
                  <div className={styles['style-button1']} onClick={this.showMessage.bind(this, record)}>
                    发送短信
                  </div>
                }
                {/* {
                  record.service === '测量' && ['已签到', '待收款', '已完成'].includes(record.state) &&
                  <div className={styles['style-button1']} onClick={this.pushOrderData.bind(this, record)}>
                    发给商家
                  </div>
                } */}
              </div>
            </div>
          )
        }
      },
    ]
    return (
      <div>
        <Table
          rowSelection={this.props.rowSelection && rowSelection}
          columns={columns}
          dataSource={this.props.data}
          size={'small'}
          rowKey={record => record.id}
          bordered
          pagination={false}
          loading={this.props.loading}
        />
        <IndexModal
          ref={this.indexModal}
          updateTable={this.props.updateTable}
        />
        <WorkerModal
          ref={this.workerModal}
          updateTable={this.updateTable}
        />
        <OrderTipModal
          ref={this.orderTipModal}
          updateTable={this.updateTipTable}
        />
        <MapModal
          ref={this.mapModal}
          updateTable={this.updateMasterTable}
        />
        <ComplaintModal
          ref={this.complaintModal}
        />
        <MeasureModal
          ref={this.measureModal}
          updateTable={this.props.updateTable}
        />
        <MasterModal
          ref={this.masterModal}
          updateMaster={this.updateMasterInfo}
        />
        <ShopModal
          ref={this.shopModal}
          updateShop={this.updateShopInfo}
        />
        <AppointModal
          ref={this.appointModal} />
        <ReminderModal
          ref={this.reminderModal} />
        <SignModal
          ref={this.signModal} />
        <MsgModal
          ref={this.msgModal} sendMessage={this.sendMessage} />
        <BonusModal
          ref={this.bonusModal} update={this.props.updateTable} />
        <AfterSaleModal
          ref={this.afterSaleModal} />
        <ComplainModal
          ref={this.complainModal} />
        <FeedModal
          ref={this.feedModal} />

        {/*
                    订单操作modal
                */}
        <Modal
          title={operationType}
          visible={operationVisible}
          width={700}
          onCancel={this.onCancelOperation}
          onOk={this.onOkOperation}
          okText='确定'
          cancelText='取消'
          confirmLoading={confirmLoading}
          destroyOnClose={true}
        >
          <div className='col vertical-lt'>
            {operationType === '推进' && this.props.data[this.state.operationIndex]
              && ['待预约', '预约失败'].includes(this.props.data[this.state.operationIndex].state) &&
              <div className='row-l'>
                <span className={styles['title-order-cost']}>预约结果:</span>
                <Radio.Group value={operationSuccess} onChange={this.onSuccessChange}>
                  <Radio value={true}>预约成功</Radio>
                  <Radio value={false}>预约失败</Radio>
                </Radio.Group>
              </div>
            }
            {operationType === '推进' && this.props.data[this.state.operationIndex]
              && ['待上门'].includes(this.props.data[this.state.operationIndex].state) &&
              <div className='row-l'>
                <span className={styles['title-order-cost']}>预约结果:</span>
                <Radio.Group value={operationSuccess} onChange={this.onSuccessChange}>
                  <Radio value={true}>推进到已签到</Radio>
                  <Radio value={false}>预约失败</Radio>
                </Radio.Group>
              </div>
            }
            {this.props.data[this.state.operationIndex] &&
              ((operationType === '推进' && (['待预约', '预约失败'].includes(this.props.data[this.state.operationIndex].state) || (!operationSuccess && this.props.data[this.state.operationIndex].state === '待上门')))
                || (operationType === '预约' && this.props.data[this.state.operationIndex].state === '待上门')
              ) &&
              <div className='col vertical-lt'>
                <div className='row-l margin-t10'>
                  <span className={styles['title-order-cost']}>{!operationSuccess ? '虚拟预约时间' : '预约时间'}:</span>
                  <DatePicker placeholder='预约时间' className='w198' locale={locale}
                    value={date ? moment(date, dateFormat) : date} format="YYYY-MM-DD" allowClear={false}
                    onChange={this.onChangeDate} disabledDate={this.getEndTime.bind(this,)}
                  />
                  <TimePicker className='w198 margin-l10' minuteStep={30} format={'HH:mm'} locale={locale}
                    value={appointDateTime ? moment(appointDateTime, 'HH:mm') : appointDateTime} onChange={this.onTimeChange} clearIcon={false}
                  />
                </div>
              </div>
            }
          </div>
          {
            !operationSuccess &&
            <div className='w100 row-l vertical-lt margin-t10'>
              <div className={`${styles['title-order-cost']} h40`}>
                失败原因:
              </div>
              <div className='row-w w486'>
                {
                  quickRemark.map((item, index) => {
                    return (
                      <div className={`${styles['quick-remark']} ${textAreaValue === item ? 'back-color9 color0' : ''}`} key={index} onClick={this.putQuickRemark.bind(this, item)}>
                        {item}
                      </div>
                    );
                  })
                }
              </div>
            </div>
          }
          {
            operationType !== '跟进' && operationType !== '预约' && operationSuccess &&
            <div className='row-l vertical-lt margin-t10'>
              <div className={`${styles['title-order-cost']} margin-r10`} >备注:</div>
              {
                <Input.TextArea value={textAreaValue} rows={4} className={styles['w465']} placeholder="输入备注" onChange={this.getInputTextAteaValue} />
              }
            </div>
          }
          {
            operationType !== '跟进' && operationType !== '预约' && !operationSuccess &&
            <div className='row-l vertical-lt margin-t10'>
              <div className={`${styles['title-order-cost']} margin-r10`} >备注:</div>
              {
                <Input.TextArea value={content} rows={4} className={styles['w465']} placeholder="输入备注" onChange={this.getInputTextContent} />
              }
            </div>
          }
          {
            operationType === '推进' && this.props.data[this.state.operationIndex]
            && ['待上门'].includes(this.props.data[this.state.operationIndex].state) && false &&
            <div className='row-l margin-t10'>
              <span className={styles['title-order-cost']}>签到图片:</span>
              <div className='row-w w500'>
                {
                  signPic.map((item, index) => {
                    return (
                      <div key={index} className={`${styles['content']}`}>
                        <div className={`row ${styles['pic-icon']}`}
                          onClick={this.delSignPic.bind(this, index)}
                        >
                          <DeleteOutlined />
                        </div>
                        <img
                          alt="暂无图片"
                          className={`${styles['pic']}`}
                          src={`${this.baseUrl}/upload/${item}`}
                        />
                        <div className={`row ${styles['content-div']}`}>
                          <EyeOutlined
                            className={`${styles['pic-icon-E']}`}
                            onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                            theme="outlined"
                            title="预览"
                          />
                        </div>
                      </div>
                    );
                  })
                }
                <Upload
                  type='file'
                  beforeUpload={this.addSignPic}
                  fileList={[]}
                  accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
                  // accept='image/*'
                  multiple={true}
                >
                  <div className={`row cursor-pointer ${styles['pic-div']}`}>
                    <div className="row h100">
                      <PlusOutlined />
                      <span>
                        新增图片
                      </span>
                    </div>
                  </div>
                </Upload>
              </div>
            </div>
          }
        </Modal>
        {/*
                    全部记录modal
                */}
        <Modal
          title={'全部记录'}
          visible={allRecordVisible}
          width={800}
          onCancel={this.onCancelAllRecord}
          onOk={this.onCancelAllRecord}
          okText='确定'
          cancelText='取消'
        >
          <Table
            columns={this.allRecorColumns}
            dataSource={allRecordData}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={{ defaultPageSize: 10 }}
          />
        </Modal>
        <Modal
          title={twiceType}
          visible={twiceVisible}
          width={660}
          okText='确定'
          cancelText='取消'
          onCancel={this.onTwiceHide}
          confirmLoading={twiceLoading}
          onOk={this.onTwiceCofirm}
        >
          {
            twiceType === '售后订单' &&
            <div className='row-l vertical-lt margin-b10'>
              <div className='w120 tr'>{twiceType}图片：</div>
              <div className='row-w w486'>
                <Upload
                  type='file'
                  beforeUpload={this.onAddTwicePic}
                  fileList={[]}
                  accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
                  // accept='image/*'
                  multiple={true}
                >
                  <div className={`row cursor-pointer ${styles['pic-div']}`}>
                    <div className="row h100">
                      <PlusOutlined />
                      <span>
                        新增图片
                      </span>
                    </div>
                  </div>
                </Upload>
                {
                  twicePic.map((item, index) => {
                    return (
                      <div key={index} className={`${styles['content']}`}>
                        <div className={`row ${styles['pic-icon']}`}
                          onClick={this.delTwicePic.bind(this, index)}
                        >
                          <DeleteOutlined />
                        </div>
                        <img
                          alt="暂无图片"
                          className={`${styles['pic']}`}
                          src={`${this.baseUrl}/upload/${item}`}
                        />
                        <div className={`row ${styles['content-div']}`}>
                          <EyeOutlined
                            className={`${styles['pic-icon-E']}`}
                            onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                            theme="outlined"
                            title="预览"
                          />
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          }
          <div className='row-l vertical-lt margin-b10'>
            <div className='w120 tr'>{twiceType}备注：</div>
            <Input.TextArea value={twiceRemark} onChange={this.onTwiceRemark} rows={4} placeholder='请输入备注' className='w486' />
          </div>
          <div className='row-w w100 margin-b10'>
            {
              twiceType === '二次上门' &&
              ['货不齐', '缺少配件', '师傅空跑', '测量复尺', '安装窗帘'].map((item, index) => {
                return (
                  <div onClick={this.twiceFast.bind(this, item)} className={`${styles['quick-remark']} color10`} key={index}>{item}</div>
                );
              })
            }
          </div>
          {
            twiceType === '售后订单' &&
            <div className='margin-b10'>
              <div>说明：</div>
              <div>1售后订单发起人必须第一时间联系客户表明公司已经安排师傅来处理对应的客户的售后问题</div>
              <div>2售后发起人员必须说明清楚具体的售后原因+照片来帮助区域客服来判断师傅是否可以严格按照要求来执行（对于特殊订单没有照片的也要说明原因）</div>
              <div>3售后订单的预约时效和上门时效按照正常订单流程安排（客户特殊要求的除外）</div>
            </div>
          }
        </Modal>
        <Modal
          title='安装效果审核'
          visible={installModal.visible}
          width={760}
          okText='确定'
          cancelText='取消'
          onCancel={this.onInstallHide}
          onOk={this.putInstall}
          footer={
            <div className='w100 row-r'>
              {
                installModal.praiseStatus === '待审核' &&
                (installModal.praiseMode === '固定金额' || [7, 9, 11].includes(role)) &&
                <Button onClick={this.passGoodOrder.bind(this, '已拒绝')} className='margin-r10' type='ghost'>好评返现审核失败</Button>
              }
              {
                installModal.praiseStatus === '待审核' &&
                (installModal.praiseMode === '固定金额' || [7, 9, 11].includes(role)) &&
                <Button onClick={this.passGoodOrder.bind(this, '已通过')} className='margin-r10' type='ghost'>好评返现审核通过</Button>
              }
              <Button onClick={this.onInstallHide} className='margin-r10' type='ghost'>取消</Button>
              <Button onClick={this.putInstall} type='primary'>确认</Button>
            </div>
          }
        >
          <div className='col-t  vertical-lt'>
            {
              installModal.service === '安装' &&
              <div className='row-l vertical-lt'>
                <div className='w90 tc margin-r10'>安装效果图:</div>
                <div className='row-w w600'>
                  {
                    installModal.installPic.map((item, index) => {
                      return (
                        <div key={index} className={`${styles['content']}`}>
                          <div className={`row ${styles['pic-icon']}`} onClick={this.delInstall.bind(this, index)}><DeleteOutlined /></div>
                          <img
                            alt="暂无图片"
                            className={`${styles['pic']}`}
                            src={`${this.baseUrl}/upload/${item}`}
                          />
                          <div className={`row ${styles['content-div']}`}>
                            <EyeOutlined
                              className={`${styles['pic-icon-E']}`}
                              onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                              theme="outlined"
                              title="预览"
                            />
                          </div>
                        </div>
                      );
                    })
                  }
                  <Upload
                    type='file'
                    beforeUpload={this.onAddPic}
                    fileList={[]}
                    accept='.jpg,.png,.jpeg,.icon,.svg,.gif'
                    // accept='image/*'
                    multiple={true}
                  >
                    <div className={`row cursor-pointer ${styles['pic-div']}`}>
                      <div className="row h100">
                        <PlusOutlined />
                        <span>
                          新增图片
                        </span>
                      </div>
                    </div>
                  </Upload>
                </div>
              </div>
            }
            {
              installModal.service === '安装' &&
              <div className='row-l vertical-lt margin-t10'>
                <div className='w90 tc margin-r10'>安装视频:</div>
                <div className='row-w vertical-lt w600'>
                  <Upload
                    type='file'
                    beforeUpload={this.uploadVideo}
                    customRequest={() => { }}
                    fileList={[]}
                    multiple={false}
                    maxCount={1}
                  >
                    <div className={`row cursor-pointer ${styles['pic-div']} margin-r197`}>
                      <div className="row h100">
                        <PlusOutlined />
                        <span>
                          新增视频
                        </span>
                      </div>
                    </div>
                  </Upload>
                  {
                    installModal.videos.map((item, index) => {
                      return (
                        <div className={`${styles['video-box']} col-t vertical-lt`} key={index}>
                          <div className={`${styles['content-video']}`}>
                            <video controls='controls' className={styles['content-image']} src={item} />
                          </div>
                          <Popconfirm
                            title="您确定要删除该视频吗?"
                            onConfirm={this.delVideo.bind(this, index)}
                            okText="确认"
                            cancelText="取消"
                          >
                            <Button type='danger' className='w100 margin-t5'>删除视频</Button>
                          </Popconfirm>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            }
            {
              installModal.praiseStatus && installModal.praiseStatus !== '无' &&
              <div className='row-l vertical-lt margin-t10'>
                <div className='w90 tc margin-r10'>好评图片:</div>
                <div className='row-w w600'>
                  {
                    installModal.praisePic.map((item, index) => {
                      return (
                        <div key={index} className={`${styles['content']}`}>
                          <img
                            alt="暂无图片"
                            className={`${styles['pic2']}`}
                            src={`${this.baseUrl}/upload/${item}`}
                          />
                          <div className={`row ${styles['content-div2']}`}>
                            <EyeOutlined
                              className={`${styles['pic-icon-E']}`}
                              onClick={this.preview.bind(this, `${this.baseUrl}/upload/${item}`)}
                              theme="outlined"
                              title="预览"
                            />
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            }
            {
              installModal.praiseStatus && installModal.praiseStatus !== '无' &&
              <div className='row-l vertical-lt margin-t10'>
                <div className='w90 tc margin-r10'>好评申请说明:</div>
                <div className='w600'>{installModal.praiseRemark}</div>
                {/* {
                  installModal.praiseStatus === '待审核' ?
                    <Input.TextArea value={installModal.praiseRemark}
                      onChange={this.changePraiseRemark} className='w500' rows={4} />
                    :
                    <div>{installModal.praiseStatus + '  ;  '}{installModal.praiseRemark}</div>
                } */}
              </div>
            }
            {/* <div>{installModal.praiseMode}</div> */}
            {
              installModal.praiseStatus === '待审核' &&
                (installModal.praiseMode === '固定金额' || [7, 9, 11].includes(role)) ?
                (<div className='row-l margin-t10'>
                  <div className='w90 tc margin-r10'>好评金额:</div>
                  <InputNumber value={installModal.price_s}
                    onChange={this.changePraisePrice.bind(this, 'price_s')} addonBefore='商户' min={0} className='w166 margin-r10' />
                  <InputNumber value={installModal.price_w}
                    onChange={this.changePraisePrice.bind(this, 'price_w')} addonBefore='师傅' min={0} className='w166' />
                </div>)
                : <div></div>
            }

          </div>
        </Modal>
      </div>
    );
  }
  setWorkerDisplay = (index) => {
    this.props.setDisplay(index)
  }
  getWorkerPhone = (phone, type) => {
    if (!phone) {
      return;
    }
    let newPhone = phone;
    if (!type) {
      newPhone = phone.substring(0, 3) + '****' + phone.substring(7);
    }
    return newPhone;
  }
  confirmTag = async (e, index, indexs) => {
    let res = await delLabel(e);
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.props.delLabelData(index, indexs)
  }
  //时间类型转换
  removeT = (e) => {
    let date = e.split('T');
    date = date.join(' ');
    return date
  }
  updateTipTable = () => {
    this.props.updateTipData();
  }
  addOrderTip = (record) => {
    this.orderTipModal.current.onShowModal(record);
  }
  allRecordClick = (data) => {
    this.setState({
      allRecordVisible: true,
      allRecordData: data,
    })
  }
  onCancelAllRecord = () => {
    this.setState({
      allRecordVisible: false,
    })
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    let { data } = this.props;
    let newSelectedRowKeys = [];
    let newData;
    if (this.props.onType === '催单') {
      newData = data.filter(r => r.urge_contact === '否');
    } else if (this.props.onType === '好评') {
      newData = data.filter(r => r.bonus_order === '是');
    } else if (this.props.onType === '待审核') {
      newData = data.filter(r => r.state === '待审核');
    } else if (this.props.onType === '催单处理') {
      newData = data.filter(r => r.urge_contact === '是');
    }
    newData.forEach(item => {
      let dataID = selectedRowKeys.filter(r => r === item.id)[0];
      if (dataID) {
        newSelectedRowKeys.push(dataID);
      }
    })
    // this.setState({
    //     selectedRowKeys: newSelectedRowKeys
    // });
    this.props.onSelectChange(newSelectedRowKeys)
  }
  pushOrderData = async (e) => {
    let res = await puOrderData(e.id);
    if (res.status === 200) {
      message.success('发送成功');
      return;
    }
  }
  //催单
  onReminder = async (id) => {
    this.setState({
      urgeLoading: true,
    });
    let res = await putUrgecontact([id]);
    if (res.status !== 204) {
      message.error(res.data);
      this.setState({
        urgeLoading: false,
      });
      return;
    }
    this.setState({
      urgeLoading: false,
    });
    this.props.getDataList();
  }
  updateTable = (row, type) => {
    if (row) {
      this.setState({
        workerData: row
      })
    }
  }
  //打开modal
  openModal = (e) => {
    let { operationIndex } = this.state;
    let city = this.props.data[operationIndex].city;
    this.workerModal.current.onShowModal(e, city);
  }
  //预约时间
  onChangeDate = (dates, dateStrings) => {
    this.setState({
      date: dateStrings
    })
  }
  //修改预约
  onTimeChange = (time, timeString) => {
    this.setState({
      appointDateTime: timeString
    })
  }
  //预约结果
  onSuccessChange = (e) => {
    let date = this.state.date;
    let appointDateTime = this.state.appointDateTime;
    if (e.target.value) {
      date = new Date().toLocaleDateString().split('/').join('-');
      appointDateTime = moment().format('HH') + ':00';
    } else {
      date = null;
      appointDateTime = null;
    }
    this.setState({
      operationSuccess: e.target.value,
      date, appointDateTime,
    });
  }
  putQuickRemark = (e) => {
    let textAreaValue = JSON.parse(JSON.stringify(this.state.textAreaValue));
    // textAreaValue = textAreaValue + e;
    textAreaValue = e;
    this.setState({
      textAreaValue,
    });
  }
  onOperationClick = async (data, type, index) => {
    let { date, textAreaValue, appointDateTime } = this.state;
    if (type === '推进' && data.state === '待开发') {
      message.warn('请先分配师傅');
      return;
    }
    if (type === '推进' && !['待预约', '预约失败', '待上门'].includes(data.state)) {
      this.setState({
        confirmLoading: true
      })
      let newDate = date + 'T' + appointDateTime;
      let res = await putPushorder(data.id, newDate, '', false, '');
      this.setState({
        confirmLoading: false
      })
      if (res.status !== 200) {
        message.error(res.data);
        return;
      }
      this.props.onHoverSet(index, 'state', res.data);
    } else {
      this.setState({
        operationType: type,
        operationID: data.id,
        operationOrderTime: data.create_time,
        operationIndex: index,
        operationVisible: true
      })
    }
  }
  //分配师傅
  allotMaster = (data, type, index) => {
    this.setState({
      operationType: type,
      operationID: data.id,
      operationIndex: index,
    })
    this.mapModal.current.onShow(data)
  }
  updateMasterTable = (e) => {
    this.setState({
      workerData: e
    }, () => this.onOkOperation())
  }
  //预约备注
  getInputTextAteaValue = (e) => {
    this.setState({
      textAreaValue: e.target.value
    })
  }
  //预约失败备注
  getInputTextContent = (e) => {
    this.setState({
      content: e.target.value
    })
  }
  getAllocata = () => {

  }
  getRelativeManager = async (e) => {
    if (!e) { return; }
    let res = await getMasterManager(e)
    if (res.status === 200) {
      let name = res.data[0] ? res.data[0].name : '';
      // return name;
      return <div>{name}</div>;
    }
  }
  //好评返现审核
  passGoodOrder = async (state) => {
    const { currentOrder, installModal } = this.state;
    // if (state === '已拒绝' && !installModal.praiseRemark) {
    //   message.warn('请填写拒绝原因');
    //   return;
    // }
    let res = await putCheckbonus(currentOrder, state, '', installModal.price_s, installModal.price_w)
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.onInstallHide();
    this.props.getDataList();
  }
  //申请好评
  onBonusShow = (e) => {
    this.bonusModal.current.onShow(e);
  }
  //上传测量数据modal
  uploadMeasure = async (e) => {
    let res = await getCheckServicer(e.id);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    if (res.data.canOpen) {
      this.measureModal.current.onShowModal(e);
    } else {
      let rsp = window.confirm(`当前订单已被${res.data.opener}打开,是否强行打开？`);
      if (rsp) {
        this.measureModal.current.onShowModal(e);
      }
    }
  }
  //修改师傅信息
  onEditMaster = async (e) => {
    let rsp = await getMasterManager(e.workerId)
    if (rsp.status === 200) {
      let data = {
        type: 'edit',
        id: e.workerId,
        manager: rsp.data[0] ? rsp.data[0].name : '',
      }
      this.masterModal.current.showDrawer(data);
    }
  }
  updateMasterInfo = (e) => {
  }
  onEditShop = async (e) => {
    const { role } = this.state;
    if ([1, 7, 9, 11].includes(role)) {
      let res = await getShopInfo(e.shop);
      if (res.status === 200) {
        let data = {
          type: 'edit',
          currentShop: res.data
        }
        this.shopModal.current.showDrawer(data);
      }
    }
  }
  updateShopInfo = () => {
  }
  //完成
  onOkOperation = async () => {
    let { operationType, workerData, operationID, operationIndex, date, operationSuccess,
      textAreaValue, appointDateTime, content } = this.state;
    let res;
    if (operationType === '跟进') {
      res = await postFollow(operationID, textAreaValue);
    } else if (operationType === '订单分配') {
      res = await putAllocate(operationID, workerData.id);
    } else if (operationType === '推进') {
      //待预约-》待上门
      let newDate = ''
      //失败||(待预约||预约失败)&成功
      if (!operationSuccess || operationSuccess && this.props.data[this.state.operationIndex].state !== '待上门') {
        if ((!date || !appointDateTime) && operationSuccess) {
          message.warn('请选择时间');
          return;
        }
        if (operationSuccess || (date && appointDateTime)) {
          newDate = moment(date).format('YYYY/MM/DD') + '-' + appointDateTime + ':00';
          newDate = new Date(newDate).toLocaleString();
        }
      }

      res = await putPushorder(operationID, newDate, textAreaValue, operationSuccess, content);
    } else if (operationType === '终止') {
      res = await putCancelorder(operationID, textAreaValue);
    } else if (operationType === '预约') {
      let newDate = date + 'T' + appointDateTime;
      res = await putAppointTime(operationID, newDate);
    }
    if (res.status !== 200 && res.status !== 204) {
      this.setState({
        confirmLoading: false
      })
      message.error(res.data);
      return;
    }
    message.success(operationType + '成功')
    this.onCancelOperation();
    this.setState({
      confirmLoading: false
    })
    this.props.getDataList();
  }
  //关闭modal
  onCancelOperation = () => {
    this.setState({
      operationVisible: false,
      workerData: {
        id: 0,
        name: ''
      },
      textAreaValue: '',
      content: '',
      signPic: [],
      date: new Date().toLocaleDateString().split('/').join('-'),
      operationSuccess: true,
      appointDateTime: moment().format('HH') + ':00',
      operationIndex: 0,
    })
  }
  //打开modal
  onOpenModal = (data, type) => {
    this.indexModal.current.onShowModal(data, type);
  }
  //二次上门
  onTwiceRemark = (e) => {
    this.setState({
      twiceRemark: e.currentTarget.value,
    })
  }
  onAddTwicePic = async (file, fileList) => {
    let twicePic = _.cloneDeep(this.state.twicePic)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = twicePic.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      twicePic[leng + i] = name;
    }
    this.setState({
      twicePic
    })
  }
  delTwicePic = (index) => {
    let twicePic = _.cloneDeep(this.state.twicePic)
    twicePic.splice(index, 1)
    this.setState({
      twicePic
    })
  }
  twiceFast = (e) => {
    let twiceRemark = this.state.twiceRemark + e + ';';
    this.setState({
      twiceRemark
    })
  }
  onTwiceCofirm = async () => {
    const { twiceId, twiceType, twiceRemark, twicePic } = this.state;
    let pic = JSON.stringify(twicePic);
    let res = await putTwice(twiceId, twiceRemark, twiceType, pic)
    if (res.status === 204) {
      this.props.getDataList();
      this.onTwiceHide()
    } else {
      message.error(res.data);
      this.onTwiceHide()
      return;
    }

  }
  onTwiceShow = (e, type, orderInfo) => {
    this.setState({
      twiceVisible: true,
      twiceType: type,
      twiceId: e,
      twiceRemark: '',
      twicePic: [],
    });
  }
  onTwiceHide = () => {
    this.setState({
      twiceVisible: false,
      twiceId: 0,
      twiceRemark: '',
      twiceType: '',
      twicePic: [],
    });
  }
  //申请售后理赔
  onAfterSale = (e) => {
    this.afterSaleModal.current.onShow(e);
  }
  //安装效果图
  getInstall = async (e, shop, bonus_type) => {
    let res = await getOrder(e)
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    let installModal = {
      visible: true,
      installPic: [],
      praisePic: [],
      praiseRemark: '',
      praiseStatus: '',
      praiseMode: '',
      price_s: 0,
      price_w: 0,
      service: '',
      vod: [],
      videos: [],
    };
    installModal.installPic = res.data.pic && res.data.pic.length > 5 ? JSON.parse(res.data.pic) : [];
    installModal.praisePic = res.data.bonus_pic && res.data.bonus_pic.length > 5 ? JSON.parse(res.data.bonus_pic) : [];
    installModal.praiseMode = bonus_type ? bonus_type : '';
    installModal.praiseStatus = res.data.bonus_state ? res.data.bonus_state : '';
    installModal.praiseRemark = res.data.bonus_remark ? res.data.bonus_remark : '';
    installModal.service = res.data.service ? res.data.service : '';
    if (bonus_type === '固定金额') {
      let rsp = await getShopInfo(shop);
      if (rsp.status === 200) {
        installModal.price_s = rsp.data.price_shop;
        installModal.price_w = rsp.data.price_worker;
      }
    }
    if (res.data.vod && res.data.vod.includes('[') && res.data.vod.includes(']')) {
      installModal.vod = JSON.parse(res.data.vod);
      installModal.videos = JSON.parse(res.data.vod);
      this.getInstallVideo(installModal);
    } else {
      installModal.vod = [];
      installModal.videos = [];
    }
    // installModal.vod = res.data.service ? res.data.service : '';
    this.setState({
      installModal,
    });
  }
  putInstall = async () => {
    const { currentOrder, installModal } = this.state;
    let data = {
      pic: JSON.stringify(installModal.installPic),
      vod: JSON.stringify(installModal.vod),
      remark: '',
    }
    let res = await putInstallPic(currentOrder, data);
    if (res.status !== 204) {
      message.error(res.data);
      return;
    }
    this.onInstallHide()
    this.props.getDataList();
  }
  delInstall = (e) => {
    let installModal = _.cloneDeep(this.state.installModal)
    installModal.installPic.splice(e, 1);
    this.setState({
      installModal,
    });
  }
  changePraiseRemark = (e) => {
    let installModal = _.cloneDeep(this.state.installModal)
    installModal.praiseRemark = e.target.value;
    this.setState({
      installModal,
    });
  }
  changePraisePrice = (type, e) => {
    let installModal = _.cloneDeep(this.state.installModal)
    installModal[type] = e ? e : 0;
    this.setState({
      installModal,
    });

  }
  onInstallShow = (e) => {
    this.setState({
      currentOrder: e.id,
    });
    this.getInstall(e.id, e.shop, e.bonus_type)
  }
  onInstallHide = () => {
    this.setState({
      installModal: {
        visible: false,
        installPic: [],
        praisePic: [],
        praiseRemark: '',
        praiseStatus: '',
        price_s: 0,
        price_w: 0,
        service: '',
        vod: [],
        videos: [],
      },
      currentOrder: 0,
    });
  }
  onAddPic = async (file, fileList) => {
    let installModal = _.cloneDeep(this.state.installModal)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = installModal.installPic.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      installModal.installPic[leng + i] = name;
    }
    this.setState({
      installModal
    })
  }
  //预览
  preview = (url) => {
    let features = "height=500, width=800, top=100, left=100, toolbar=no, menubar=no,scrollbars=no,resizable=no, location=no, status=no";
    window.open(url, "newW", features);
  }
  //上浮显示弹窗
  onMasterOver = async (record, index) => {
    let rsp = await getMasterManager(record.workerId)
    if (rsp.status === 200) {
      let mname = rsp.data[0] ? rsp.data[0] : { remark: '', name: '' };
      this.props.onHoverSet(index, 'mname', mname)
    }
  }
  onShopOver = async (record, index) => {
    let rsp = await getCustomManager(record.id)
    if (rsp.status === 200) {
      let collector = rsp.data.collector;
      let priceRemark = rsp.data.priceRemark;
      let shopManager = rsp.data.shopManager;
      this.props.onHoverSet(index, 'collector', collector)
      this.props.onHoverSet(index, 'priceRemark', priceRemark)
      this.props.onHoverSet(index, 'shopManager', shopManager)
    }
  }

  //复制
  onCopyToClip = (data) => {
    var aux = document.createElement("textarea");
    aux.value = `${'商家:' + data.shopName + '\n' + '姓名:' + data.name + '\n' + '手机号:' + data.phone + '\n' + '地址:' + data.province + data.city + data.district + data.address
      }`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  onCopyToClip2 = (data) => {
    var aux = document.createElement("textarea");
    aux.value = `${'师傅:' + data.workerName + '\n' + '手机号:' + data.workerPhone}`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  onCopyToClip3 = (value, prop) => {
    var aux = document.createElement("textarea");
    aux.value = prop + ':' + value;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  //Excel
  onExcelData = _.throttle(async (id, type) => {
    let res = await getExcel(id);
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    if (type === '测量数据下载') {
      window.location.href = res.data
    } else {
      let newFileUrl = encodeURIComponent(res.data)
      window.open(`https://view.officeapps.live.com/op/view.aspx?src=${newFileUrl}`, '_blank')
    }
  }, 1000)
  //投诉记录
  onShowComplaints = (e) => {
    this.complaintModal.current.onShowModal(e)
  }
  //预约记录显示
  appointRecord = (e) => {
    this.appointModal.current.onShow(e);
  }
  //有无催单
  isReminder = (order) => {
    let res = [];
    order.map(item => {
      if (item.type === '催单') {
        res.push(item);
      }
    })
    return res;
  }
  //催单记录显示
  reminderShow = (e) => {
    this.reminderModal.current.onShow(e);
  }
  //转待开发
  chargeToDevelop = async (e) => {
    this.orderTipModal.current.onShowModal(e, true);
    // let res = await putOrderDevelop(e.id);
    // if (res.status === 204) {
    //   this.props.getDataList();
    // }
  }

  addSignPic = async (file, fileList) => {
    let signPic = _.cloneDeep(this.state.signPic)
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    let leng = signPic.length;
    for (let i = 0; i < fileList.length; i++) {
      let name = await ossUpload(fileList[i], null);
      signPic[leng + i] = name;
    }
    this.setState({
      signPic
    })
  }
  delSignPic = (e) => {
    let signPic = _.cloneDeep(this.state.signPic)
    signPic.splice(e, 1);
    this.setState({
      signPic,
    });
  }
  showSign = (e) => {
    this.signModal.current.onShow(e);
  }
  //比较时间
  getEndTime = (current) => {
    const { operationOrderTime } = this.state;
    return moment(operationOrderTime).isSameOrAfter(moment(current));
  }
  //发短信
  showMessage = (e) => {
    this.msgModal.current.onShow(e);
  }
  sendMessage = async (e) => {
    let res = await sendMessage(e.id, e.phone);
    if (res.status === 204) {
      message.success('发送成功！');
      this.msgModal.current.onCancel()
    }
  }
  //安装效果图视频
  getInstallVideo = async (installModal) => {
    installModal.vod.map(async (item, index) => {
      let local = index;
      let res = await getVideoPlay(item);
      if (res.status === 200) {
        installModal.videos[local] = res.data.playURL;
        this.setState({
          installModal
        });
      }
    })
  }
  delVideo = (index) => {
    let installModal = _.cloneDeep(this.state.installModal);
    installModal.vod.splice(index, 1);
    installModal.videos.splice(index, 1);
    this.setState({
      installModal
    });
  }
  uploadVideo = async (file, fileList) => {
    if (fileList[fileList.length - 1] !== file) {
      return;
    }
    if (fileList.length > 1) {
      message.warn('只能上传单个视频');
      return;
    }
    let decimal = (new Date()).valueOf();
    let name = `${convert.toString36(decimal)}`.replace(/:/g, '-');
    let res = await getVideoInfo(name, file.name);
    let uploadAddress = JSON.parse(decodeURI(atob(res.data.uploadAddress)));
    let uploadInfos = {
      video: res.data.videoId,
      Endpoint: uploadAddress.Endpoint,
      Bucket: uploadAddress.Bucket,
      object: null,
    };
    let that = this;
    this.uploader = new AliyunUpload.Vod({
      userId: '112',
      region: 'cn-shanghai',
      partSize: 1048576,
      parallel: 5,
      retryCount: 3,
      retryDuration: 2,
      'onUploadstarted': async (uploadInfo) => {
        this.uploader.setUploadAuthAndAddress(uploadInfo, res.data.uploadAuth, res.data.uploadAddress, res.data.videoId);
      },
      'onUploadEnd': async function (uploadInfo) {
        let rsp = await getVideoSub(res.data.videoId);
        if (rsp.status === 204) {
          that.getVideoUrl(res.data.videoId);
        } else {
          message.error(rsp.data);
        }
      }
    });
    this.uploader.addFile(file, uploadInfos.Endpoint, uploadInfos.Bucket, null, '{"Vod":{}}');
    this.uploader.startUpload();
  }
  getVideoUrl = async (e) => {
    let res = await getVideoPlay(e);
    if (res.status === 200) {
      let installModal = _.cloneDeep(this.state.installModal);
      installModal.vod.push(e);
      installModal.videos.push(res.data.playURL);
      this.setState({
        installModal
      })
    }
  }
  //投诉
  toComplain = (e) => {
    this.complainModal.current.onShow(e);
  }
  //反馈
  toFeed = (e) => {
    this.feedModal.current.onShow(e, 'new');
  }
}

export default Index;