import { message, Table, Input, Button } from 'antd';
import React, { Component } from 'react';
import Search from '../../../components/financeSearch';
import PageTurning from '../../../components/pageTurning';
import { getWorkerflist } from '../../../services/orderFinance';
import CreditCard from '../../../resource/png/credit-card.png';
import Person from '../../../resource/png/person.png';
import Weixinzhifu from '../../../resource/png/weixinzhifu.png';
import Alipay from '../../../resource/png/alipay.png';
import Link from '../../../resource/png/masterManagement-link.png';
import styles from './index.module.css';
import IndexModal from './indexModal';
import { getDepartment_Data } from '../../../config/storage';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      skip: 0,
      take: 10,
      searchType: '',
      pageIndex: 1,
    }
    this.pageSize = 10;
    this.role = getDepartment_Data().roles[0];
    this.indexModal = React.createRef();
    this.columns = [
      {
        title: '师傅基本信息',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='col vertical-lt margin-l10'>
              <div className='row-l'>
                <img src={Person} />
                <div className='margin-r10 color3 underline '>{record.name}</div>
                <div className='color7'>{record.uid}</div>
                <div className='margin-l10 color7'>(账号)</div>
                <img src={Link} className='margin-l10' onClick={this.onCopyToClip.bind(this, record)} />
              </div>
              <div className='color7'>{record.current_address}</div>
            </div>
          )
        }
      },
      {
        title: '余额',
        align: 'center',
        width: 100,
        dataIndex: 'balance',
        render: (text, record, index) => {
          return (
            <div className={record.balance < 0 ? 'color2 bold fz18' : ''}>{record.balance}</div>
          )
        }
      },
      {
        title: '结算时间',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>{record.account_time.split('T')[0]}</div>
          )
        }
      },
      {
        title: '当前提现账户',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div>
              {
                record.accounts.map((item, index) => {
                  return (
                    <div key={index} className='row-l color7 margin-l10'>
                      {item.type === '支付宝' && <img src={Alipay} />}
                      {item.type === '微信' && <img src={Weixinzhifu} />}
                      {item.type !== '微信' && item.type !== '支付宝' && <img src={CreditCard} />}
                      <div className='margin-l10'>收款账号:</div>
                      <div className='margin-l5'>{item.card_number}</div>
                      <div className='margin-l10'>实名绑定:</div>
                      <div className='margin-l5'>{item.name}</div>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      },
      {
        title: '操作',
        align: 'center',
        width: 100,
        render: (text, record, index) => {
          return (
            <div className='row'>
              {/* <div className={styles['button1']}>查看师傅信息</div> */}
              {/* <div className='row-l w100'> */}
              {
                this.role !== 5 &&
                <div className={`${styles['button2']} ${styles['color1']}`} onClick={this.onWorkerClick.bind(this, '调整', record.id)}>调整</div>
              }
              <div className={`${styles['button2']} ${styles['color2']} margin-l5`} onClick={this.onWorkerClick.bind(this, '提现', record.id)}>提现申请</div>
              {/* </div> */}
            </div>
          )
        }
      },
    ]
  }

  componentDidMount() {
    this.onSearch('');
  }

  render() {
    const { data, searchType } = this.state;
    return (
      <div>
        <div className='col back-color0 w100 padding20 vertical-lt'>
          <div className='row-l'>
            <div>师傅信息:</div>
            <Input placeholder={'请输入姓名或手机号码'} className={'margin-l10 margin-r20 w295'} value={searchType} onChange={this.onInputValue} />
            <Button type='primary' className={'margin-l20 margin-r20'} onClick={this.onSearch}>搜索</Button>
            <Button onClick={this.onReset}>重置</Button>
          </div>
        </div>
        <div className='margin-t10 back-color0'>
          <Table
            columns={this.columns}
            dataSource={data}
            size={'small'}
            rowKey={record => record.id}
            bordered
            pagination={false}
          />
          {/* <PageTurning pageMaps={this.pageMaps} /> */}
          <div className={'row-r padding10'}>
            <div className={`${styles['pageStyle']} margin-r10`} onClick={this.changePageIndex.bind(this, '-')} ><LeftOutlined />上一页</div>
            <div className={`${styles['pageStyle']} `} onClick={this.changePageIndex.bind(this, '+')} >下一页<RightOutlined /></div>
            <div className='row-l margin-l10 fz18'>
              向第
              {
                this.state.pageEdit ?
                  <input className={`${styles['width-50']} margin-r5 margin-l5`}
                    onFocus={this.inputOnFocus}
                    onBlur={this.inputOnBlur}
                    onChange={this.onChange}
                    value={this.state.pageIndex}
                  />
                  :
                  <div className={`${styles['width-50']} ${styles['inline']} margin-r5 margin-l5`} onClick={this.onChangeDiv}>{this.state.pageIndex}</div>
              }
              页跳转
            </div>
          </div>
        </div>
        <IndexModal
          ref={this.indexModal}
          updateTable={this.updateTable}
        />
      </div>
    );
  }
  onInputValue = (e) => {
    this.setState({
      searchType: e.target.value,
      pageIndex: 1,
    })
  }
  updateTable = () => {
    this.onSearch(this.state.searchType);
  }

  onWorkerClick = (e, id) => {
    this.indexModal.current.onShowModal(e, id);
  }
  onReset = () => {
    this.setState({
      searchType: '',
      pageIndex: 1,
    }, async () => this.onSearch(''))
  }

  onSearch = async () => {
    let { searchType, skip, take } = this.state;
    let res = await getWorkerflist({ info: searchType, ...this.pageMap() });
    if (res.status !== 200) {
      message.error(res.data);
      return;
    }
    this.setState({
      data: res.data,
    })
  }
  //复制
  onCopyToClip = (data) => {
    var aux = document.createElement("textarea");
    aux.value = `${'姓名:' + data.name + '\n' + '账号:' + data.uid + '\n' + '地址:' + data.current_address}`;
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);
    message.success('复制成功');
  }
  //换页
  // pageMaps = ({ skip, take, pageIndex }) => {
  //   this.setState({
  //     skip: skip,
  //     pageIndex: pageIndex
  //   }, async () => this.getDataList())
  // }

  //换页
  onChangeDiv = () => {
    this.setState({
      pageEdit: true,
    });
  }

  pageMap = () => {
    return ({
      skip: (this.state.pageIndex - 1) * this.pageSize,
      take: this.pageSize
    });
  }

  onChange = (e) => {
    let value = e.target.value;
    if (value === '') {
      this.setState({
        pageIndex: ''
      });
      return;
    }
    if (isNaN(value)) {
      return;
    } else {
      let number = Number(value);
      if (number < 1 || number % 1 !== 0) {
        return;
      }
    }
    this.setState({
      pageIndex: Number(e.target.value)
    });
  }

  inputOnBlur = (e) => {
    this.setState({
      pageEdit: false,
    });
    if (this.state.pageIndex === '') {
      this.setState({
        pageIndex: this.tempPageIndex,
      });
      return;
    }
    this.onSearch('');
  }

  inputOnFocus = (e) => {
    this.tempPageIndex = e.target.value;
  }

  changePageIndex = async (type) => {
    let pageIndex = _.cloneDeep(this.state.pageIndex);
    if (type === '-' && pageIndex > 1) {
      pageIndex--;
      await this.setState({
        pageIndex: pageIndex
      })
    } else if (type === '+') {
      pageIndex++;
      await this.setState({
        pageIndex: pageIndex
      })
    } else {
      message.warn('当前为第一页')
    }
    this.onSearch('');
  }
}

export default Index;