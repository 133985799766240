let host;
let ossURL;

if (process.env.NODE_ENV === 'development') {
  // host = 'http://192.168.1.55:5566';
  // host = 'http://47.96.188.225:5544';
  // host = 'http://47.96.188.225:6851';
  // host = 'https://apiapppublic.lijuyun.cn';
  host = 'https://ps.immgj.com.cn/api';
}
else {
  // host = window.location.origin + '/api';
  // host = 'https://apiapppublic.lijuyun.cn';
  host = 'https://ps.immgj.com.cn/api';
  // host = 'http://192.168.1.78:5566';

}

export { host, ossURL };

